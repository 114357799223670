import { Avatar, Container, useScrollTrigger } from "@material-ui/core";
import React from "react";
import { Slide, AppBar, Toolbar, Button } from "@material-ui/core";
import logo from "../../images/logo.png";
import "./style.scss";
import Menu from "../menu/Menu";
import { useSelector } from "react-redux";
import { ScreenSize, supportCenterMenu } from "../../constants";
import { RETURN_SAFE_LUS_KEY, SPWALLET_URL } from "../../settings";
import HeadsetMicIcon from "@material-ui/icons/HeadsetMic";

function Header(props) {
  const { setting } = useSelector((state) => state);
  const { viewMode, library } = setting;

  return (
    <HideOnScroll {...props}>
      <AppBar className="app-header bg-dark-01">
        <Toolbar>
          <Container id="tool-bar">
            <div className="d-flex-cbt w-100pc">
              <div className="d-flex-cs">
                <Avatar alt="SafeLuS" src={logo} sizes="large" />
                <div className="logo-text" translate="no">
                  <label>SAFE</label>
                  <span>LuS</span>
                </div>
                <Menu />
              </div>
              {viewMode !== ScreenSize.Mobile.value &&
              viewMode !== ScreenSize.Tablet.value ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Button
                    className="circular min-w-160 btn-bg-gradient text-bold mr-20"
                    onClick={() =>
                      window.location.replace(
                        `${SPWALLET_URL}/login?return=${RETURN_SAFE_LUS_KEY}`
                      )
                    }
                    translate="no"
                  >
                    {library.STAKE_NOW ? library.STAKE_NOW.toUpperCase() : ""}
                  </Button>
                  <div className="d-flex-cs">
                    <div className="menu">
                      <Button
                        className={"circular "}
                        style={{ minWidth: "auto" }}
                      >
                        <HeadsetMicIcon />
                      </Button>
                      <ul className="submenu">
                        {supportCenterMenu.map((menu, subIndex) => (
                          <li
                            key={subIndex}
                            onClick={() => window.open(menu.url)}
                          >
                            {menu.label}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </Container>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
}

export default Header;

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}
