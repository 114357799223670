export const formatUSD = (str) => {
  str += "";
  const deleteText = str.replace(/[^\d.]/g, ""); //clear text
  const x = deleteText.split(".");
  let x1 = x[0];
  const x2 = x[1];
  const x3 = x.length > 1 ? "." + x2.slice(0, 5) : "";
  if (!x1) x1 = "0";
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1,$2");
  }
  let result = (x1 + x3).replace(/^0+(?!\.|$)/, "").replace(/^\./, "");
  return result;
};

export const formatLuS = (str) => {
  str += "";
  const deleteText = str.replace(/[^\d.]/g, ""); //clear text
  const x = deleteText.split(".");
  let x1 = x[0];
  const x2 = x[1];
  const x3 = x.length > 1 ? "." + x2.slice(0, 2) : "";
  if (!x1) x1 = "0";
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1,$2");
  }
  let result = (x1 + x3).replace(/^0+(?!\.|$)/, "").replace(/^\./, "");
  return result;
};

export const formatLusFixDecimal = (str) => {
  str += "";
  const deleteText = str.replace(/[^\d.]/g, ""); //clear text
  const x = deleteText.split(".");
  let x1 = x[0];
  const x2 = x[1];
  const x3 = x.length > 1 ? "." + x2.slice(0, 8) : "";
  if (!x1) x1 = "0";
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1,$2");
  }
  let result = (x1 + x3).replace(/^0+(?!\.|$)/, "").replace(/^\./, "");
  return result;
};

export const formatInputLuS = (str) => {
  str += "";
  const deleteText = str.replace(/[^\d]/g, ""); //clear text
  const x = deleteText.split(".");
  let x1 = x[0];
  const x2 = x[1];
  const x3 = x.length > 1 ? "." + x2.slice(0, 9) : "";
  if (!x1) x1 = "0";
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1,$2");
  }
  let result = (x1 + x3).replace(/^0+(?!\.|$)/, "").replace(/^\./, "");
  return result;
};

export const deleteText = (str) => {
  str += "";
  const deleteText = str.replace(/[^\d.]/g, ""); //clear text
  return deleteText;
};
