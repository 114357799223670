/* eslint-disable jsx-a11y/alt-text */
import { Container } from "@material-ui/core";
import React from "react";
import roadmapImage1 from "../../images/roadmap-image-1.png";
import roadmapImage2 from "../../images/roadmap-image-2.png";

const routes = [
  { key: "Q2/2021", title: "ICO and Private Sale" },
  { key: "05/2021", title: "Listing SPexchange" },
  {
    key: "06/2021",
    title: "SAFELuS Launching Airdrop LUS Token to the Community",
  },
  {
    key: "Q3/2021",
    title: "Launch of the global shopping LUS.SHOPPING website",
    sub: [
      "Opening of the headquarters in Changwon Korea ",
      "Listing LUS on Pancake",
      "Listing LUS on CMC, Coingecko",
    ],
  },
  {
    key: "Q4/2021",
    title: "Launch of the reverse auction",
    sub: ["Launching a shopping app"],
  },
  {
    key: "Q1/2022",
    title:
      "Developing shopping market in Southeast Asia, China both franchise and e-commerce",
  },
  {
    key: "Q2/2022",
    title: "Reach 500,000 holder LuS worldwide",
  },
  {
    key: "Q3/2022",
    title: "30 branches in southeast Asia, Mainland",
  },
  {
    key: "Q4/2022",
    title: "Establishment of a presentative office in Southeast Asia",
  },
];

function Roadmap() {
  return (
    <Container maxWidth={false} className="roadmap">
      <Container>
        <label className="title">The Roadmap</label>
        <div className="roadmap-content">
          <div className="hide-mobile">
            <div>
              <img src={roadmapImage1} />
            </div>
            <div>
              <img src={roadmapImage2} />
            </div>
          </div>
          <ul>
            {routes.map((item, index) => (
              <li key={index}>
                <div className="year">
                  <span>{item.key}</span>
                </div>
                <div className="blank"></div>
                <div className="info">
                  <label htmlFor="">{item.title}</label>
                  <br />
                  {/* <p> */}
                  {item.sub &&
                    item.sub.map((item, index) => (
                      <React.Fragment key={"x_" + index}>
                        <label>{item}</label>
                        <br />
                      </React.Fragment>
                    ))}
                  <p></p>
                  {/* </p> */}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Container>
    </Container>
  );
}

export default Roadmap;
