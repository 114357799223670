import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { LUS_TOKEN } from "../constants";
import { formatLuS } from "../settings/format";
import { CircularProgress, Grid, TablePagination } from "@material-ui/core";
import { _getStakeHistory } from "../actions/safeLusActions";

export default function StakeHistory() {
  const { safeLus, setting } = useSelector((state) => state);
  const { stakeHistory } = safeLus;
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { library } = setting;

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(1);
  };

  useEffect(() => {
    dispatch(_getStakeHistory(page, rowsPerPage));
    const interval = setInterval(() => {
      dispatch(_getStakeHistory(page, rowsPerPage));
    }, 30000);
    return () => clearInterval(interval);
  }, [dispatch, page, rowsPerPage]);

  return (
    <div
      className="gray-bg pd-20 mobile-br-0 mobile-p-0 br-5"
      style={{ color: "#fff" }}
    >
      <Grid></Grid>
      <TableContainer>
        <Table size="small" aria-label="a dense table">
          <TableHead className="white-color hide-mobile opacity-08">
            <TableRow>
              <TableCell>{library.ACCOUNT}</TableCell>
              <TableCell>
                {library.AMOUNT} ({LUS_TOKEN})
              </TableCell>
              <TableCell>{library.STATUS}</TableCell>
              <TableCell>{library.TIME}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stakeHistory ? (
              stakeHistory.items.length > 0 ? (
                stakeHistory.items.map((row, index) => (
                  <React.Fragment key={index}>
                    <TableRow className="hide-mobile">
                      <TableCell className="text-bold">
                        {row.displayName}
                      </TableCell>
                      <TableCell className="text-bold">
                        {formatLuS(row.initAmount)}
                      </TableCell>
                      <TableCell>{library[row.status]}</TableCell>
                      <TableCell component="th" scope="row">
                        {moment(row.createdDate).format("MM-DD-YYYY HH:mm:ss")}
                      </TableCell>
                    </TableRow>
                    <TableRow className="show-mobile show-mobile-table-row">
                      <TableCell colSpan={4}>
                        <div className="d-flex-sbt">
                          <div>
                            <div className="text-bold">{row.displayName}</div>
                            <div>
                              <small>
                                {moment(row.createdDate).format(
                                  "MM-DD-YYYY HH:mm:ss"
                                )}
                              </small>
                            </div>
                          </div>
                          <div style={{ textAlign: "right" }}>
                            <div className="text-bold">
                              {formatLuS(row.initAmount)} {LUS_TOKEN}
                            </div>
                            <div>
                              <small>{library[row.status]}</small>
                            </div>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} className="text-center">
                    {library.NO_RECORDS_FOUND}
                  </TableCell>
                </TableRow>
              )
            ) : (
              <TableRow>
                <TableCell colSpan={4}>
                  <div style={{ textAlign: "center" }}>
                    <CircularProgress style={{ margin: "auto" }} />
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {stakeHistory && (
          <TablePagination
            component="div"
            count={stakeHistory.itemCount}
            page={page - 1}
            onChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </TableContainer>
    </div>
  );
}
