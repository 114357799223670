import { Grid, Link, Typography, Container, Avatar } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import logo from "../images/logo.png";

function Footer() {
  const { setting } = useSelector((state) => state);
  const { library } = setting;
  return (
    <>
      <div className="footer">
        <Container>
          <Grid container justify="space-between">
            <Grid className="app-header" item xs={12} md={3}>
              <div className="d-flex-cs">
                <Avatar alt="SafeLuS" src={logo} sizes="large" />
                <div className="logo-text">
                  <label>SAFE</label>
                  <span>LuS</span>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={3}>
              <div>
                <Typography className="hide-mobile">
                  {library.SUPPORT}
                </Typography>
                <Link href="#">
                  <p>support@lus.shopping</p>
                </Link>
                <Link href="#">
                  <p>bussiness@lus.shopping</p>
                </Link>
              </div>
            </Grid>
            <Grid item xs={6} md={3}>
              <div>
                <Typography>{library.SERVICE}</Typography>
                <Link href="#">
                  <p>{library.SHOPPING_ONLINE}</p>
                </Link>
                <Link href="#">
                  <p>{library.STAKE_MINING}</p>
                </Link>
                <Link href="#">
                  <p>{library.KOL_PARTNERSHIP}</p>
                </Link>
              </div>
            </Grid>
            <Grid item xs={6} md={3}>
              <div>
                <Typography>{library.LEGAL}</Typography>
                <Link href="#">
                  <p>{library.TEMP_AND_CONDITIONS}</p>
                </Link>
                <Link href="#">
                  <p>{library.PRIVACY_POLICY}</p>
                </Link>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}

export default Footer;
