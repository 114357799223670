import { get } from "../utils/api";
import {
  API_GET_F1_AFFILIATE,
  API_GET_MY_OPEN_SAFE_HISTORY,
  API_GET_POOL_LIST,
  API_GET_SAFE_LUS_STATISTIC,
  API_GET_STAKING_V1,
  API_GET_TOP_HOLDER,
  API_GET_TOP_UP_LEVEL,
  API_GET_TRANSACTION,
  API_GET_USER_PROFIT_STATISTIC,
  API_GET_USER_STATISTIC,
  API_SAFE_LUS_SETTING,
  API_STAKE_HISTORY,
  APT_GET_BALANCE,
} from "../settings/apiEndpoint";
import { getAccessToken, isLoggedIn } from "../utils/auth";
import {
  GET_F1_AFFILIATE,
  GET_LUS_BALANCE,
  GET_LUS_PRICE,
  GET_MY_OPEN_SAFE_HISTORY,
  GET_MY_STAKED,
  GET_MY_UNSAFE_HISTORY,
  GET_POOL_LIST,
  GET_SAFE_LUS_STATISTIC,
  GET_SETTING,
  GET_STAKE_HISTORY,
  GET_STAKING_V1,
  GET_TOP_HOLDER,
  GET_TOP_UP_SETTING,
  GET_TRANSACTION,
  GET_USER_PROFIT_STATISTIC,
  GET_USER_STATISTIC,
  IN_RELEASE,
  LUS_M_TOKEN,
  LUS_TOKEN,
  OPEN,
} from "../constants";
import { API_GET_MARKET_LUS } from "../settings";

export const _getTopUpSetting = () => (dispatch) => {
  if (isLoggedIn())
    get(API_GET_TOP_UP_LEVEL, (data) => {
      dispatch({
        type: GET_TOP_UP_SETTING,
        payload: data,
      });
    });
};

export const _getSetting = () => (dispatch) => {
  const checkLogin = getAccessToken();
  if (checkLogin) {
    get(API_SAFE_LUS_SETTING, (data) =>
      dispatch({
        type: GET_SETTING,
        payload: data,
      })
    );
  }
};

export const _getMyTotalStakingAmount = () => (dispatch) => {
  const checkLogin = getAccessToken();
  if (checkLogin) {
    get(
      API_GET_MY_OPEN_SAFE_HISTORY + `?from=0&to=0&page=${1}&pageSize=${1000}`,
      (data) => {
        dispatch({
          type: GET_MY_STAKED,
          payload: {
            myUSDTotalStakingAmount: data.meta.totalOpenStakingUsdAmount,
            myTotalStakingAmount: data.meta.totalOpenStakingAmount,
            myTotalEstimatedBalance: data.meta.totalEstimatedBalance,
          },
        });
      }
    );
  }
};

export const _getLusPrice = () => (dispatch) => {
  fetch(API_GET_MARKET_LUS)
    .then((res) => res.json())
    .then((json) => {
      const data = {
        lastPrice: 0.0005,
        lastPriceUsd: 0.0005,
        priceChange: 0,
        priceChangePercent: 0,
        high: 0.0005,
        low : 0.0005
      }

      dispatch({
        type: GET_LUS_PRICE,
        payload: { lusPrice: data.lastPrice, lusTokenStatistic: data },
      });
    });
};

export const _getBalance = () => (dispatch) => {
  const checkLogin = getAccessToken();
  if (checkLogin) {
    get(APT_GET_BALANCE, (data) => {
      const lusBalance = data.filter((item) => item.coin === LUS_TOKEN)[0]
        .amount;
      const lusMBalance = data.filter((item) => item.coin === LUS_M_TOKEN)[0]
        .amount;
      dispatch({
        type: GET_LUS_BALANCE,
        payload: {
          lusBalance,
          lusMBalance,
        },
      });
    });
  }
};

export const _getStakeHistory = (page, pageSize) => (dispatch) => {
  const checkLogin = getAccessToken();
  if (checkLogin) {
    get(
      `${API_STAKE_HISTORY}?from=0&to=0&page=${page}&pageSize=${pageSize}`,
      (data) =>
        dispatch({
          type: GET_STAKE_HISTORY,
          payload: data,
        })
    );
  }
};

export const _getPoolList = () => (dispatch) => {
  const checkLogin = getAccessToken();
  if (checkLogin) {
    get(API_GET_POOL_LIST, (data) =>
      dispatch({
        type: GET_POOL_LIST,
        payload: data,
      })
    );
  }
};

export const _getStakingV1 = () => (dispatch) => {
  const checkLogin = getAccessToken();
  if (checkLogin) {
    get(API_GET_STAKING_V1, (data) => {
      dispatch({
        type: GET_STAKING_V1,
        payload: data,
      });
    });
  }
};

export const _getTransaction = (type, page, pageSize) => (dispatch) => {
  const checkLogin = getAccessToken();
  if (checkLogin) {
    dispatch({
      type: GET_TRANSACTION,
      payload: null,
    });
    get(
      API_GET_TRANSACTION +
      `?from=0&to=0&type=${type}&&pageSize=${pageSize}&&page=${page}`,
      (data) =>
        dispatch({
          type: GET_TRANSACTION,
          payload: data,
        })
    );
  }
};

export const _getMyOpenStake = (page, pageSize) => (dispatch) => {
  const checkLogin = getAccessToken();
  if (checkLogin) {
    get(
      API_GET_MY_OPEN_SAFE_HISTORY +
      `?from=0&to=0&page=${page}&pageSize=${pageSize}`,
      (data) =>
        dispatch({
          type: GET_MY_OPEN_SAFE_HISTORY,
          payload: data,
        })
    );
  }
};

export const _getMyUnsafeHistory = (page, pageSize) => (dispatch) => {
  const checkLogin = getAccessToken();
  if (checkLogin) {
    get(
      API_GET_MY_OPEN_SAFE_HISTORY +
      `?from=0&to=0&page=${page}&pageSize=${pageSize}`,
      (data) =>
        dispatch({
          type: GET_MY_UNSAFE_HISTORY,
          payload: data,
        })
    );
  }
};

export const _getTopHolder = (page, pageSize) => (dispatch) => {
  dispatch({
    type: GET_TOP_HOLDER,
    payload: null,
  });
  get(API_GET_TOP_HOLDER + `?page=${page}&pageSize=${pageSize}`, (data) =>
    dispatch({
      type: GET_TOP_HOLDER,
      payload: data,
    })
  );
};

export const _getUserStatistic = () => (dispatch) => {
  get(API_GET_USER_STATISTIC, (data) =>
    dispatch({
      type: GET_USER_STATISTIC,
      payload: data,
    })
  );
};

export const _getUserProfitStatistic = () => (dispatch) => {
  const checkLogin = getAccessToken();
  if (checkLogin) {
    get(API_GET_USER_PROFIT_STATISTIC, (data) =>
      dispatch({
        type: GET_USER_PROFIT_STATISTIC,
        payload: data,
      })
    );
  }
};

export const _getF1Affiliate = (page, pageSize) => (dispatch) => {
  const checkLogin = getAccessToken();
  if (checkLogin) {
    get(API_GET_F1_AFFILIATE + `?page=${page}&pageSize=${pageSize}`, (data) =>
      dispatch({
        type: GET_F1_AFFILIATE,
        payload: data,
      })
    );
  }
};

export const _getSafeLusStatistic = () => (dispatch) => {
  get(API_GET_SAFE_LUS_STATISTIC + `?page=1&pageSize=10`, (data) =>
    dispatch({
      type: GET_SAFE_LUS_STATISTIC,
      payload: data,
    })
  );
};
