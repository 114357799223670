import { Button, ClickAwayListener, Tooltip } from "@material-ui/core";
import React from "react";
import HelpIcon from "@material-ui/icons/Help";

function CustomTooltip({ content, style, className }) {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div className={className}>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={content}
          onMouseLeave={handleTooltipClose}
          style={style}
          className="tooltip"
        >
          <Button onClick={handleTooltipOpen} onMouseOver={handleTooltipOpen}>
            <HelpIcon />
          </Button>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
}

export default CustomTooltip;
