export const english = {
  home: "Home",
  my_safe: "My Stake",
  affiliate: "Affiliate",
  events: "Events",
  IN_RELEASE: "Unstake",
  OPEN: "Staking",
  UNSAFE: "Unstake",
  AVAILABLE: "Available",
  POOLS_HOLDER: "Pools holder",
  POOLS_REWARD: "Pools reward",
  POOLS_PROMOTION: "Pools Promotion",
  DAYS: "days",
  HOURS: "hours",
  MINUTES: "minutes",
  SECONDS: "seconds",
  NO_RECORDS_FOUND: "No records found",
  TIME: "Time",
  ACCOUNT: "Account",
  AMOUNT: "Amount",
  STATUS: "Status",
  STAKE_HISTORY: "Stake history",
  TOP_HOLDER: "Top holder",
  MY_STAKED: "My staked",
  ESTIMATED_BALANCE: "Estimated balance",
  MONTHLY: "Total",
  DAILY: "Daily",
  HISTORY_STAKE_UNSTAKE: "History Stake/Unstake",
  RECEIVE_POOLS_HOLDER: "Receive pools holder",
  RECEIVE_POOLS_REWARD: "Receive pools reward",
  RECEIVE_POOLS_PROMOTION: "Receive pools promotion",
  RECEIVE_EVENTS: "Receive events",
  STAKE_NOW: "Stake now",
  AFFILIATE_COMMISSION: "Affiliate commission",
  DAILY_RELEASE: "Daily release",
  MY_LEVEL: "My level",
  MY_RANK: "My rank",
  TOTAL_COMMISSION: "Total commission",
  TOTAL_REVENUE: "Total revenue",
  RANK: "Rank",
  LEVEL: "Level",
  STAKED: "Staked",
  MEMBERS: "Affiliate",
  TOP: "Top",
  SAFE_LUS_SUCCESS: "Congratulations! You have successfully stake",
  CANCEL: "Cancel",
  SAFE_LUS: "Stake LuS",
  TRANSFER_TO_SAFE_LUS: "Transfer to Safe LuS",
  SELECT_STAKING: "Select staking",
  PLEASE_ENTER_AMOUNT_YOU_WANT_TO_SAFE: "Please enter amount you want to safe",
  PLEASE_ENTER_AMOUNT: "Please enter amount",
  INVALID_AMOUNT: "Invalid amount",
  PLEASE_SELECT_STAKING: "Please select you staking you want to transfer",
  UNSAFE_SUCCESS: "Unstake success with ID #",
  LOGOUT: "Logout",
  DASHBOARD: "Dashboard",
  SUPPORT: "Support",
  RELEASING: "Releasing",
  BLOCK_AMOUNT: "Blocking",
  TOTAL_EARNED: "Total earned",
  TOTAL_BLOCKING: "Total blocking",
  SERVICE: "Service",
  LEGAL: "Legal",
  SHOPPING_ONLINE: "Shopping online",
  STAKE_MINING: "Stake mining",
  KOL_PARTNERSHIP: "KOLs partnership",
  TEMP_AND_CONDITIONS: "Term & Conditions",
  PRIVACY_POLICY: "Privacy policy",
  SAFE_NOW: "Stake now",
  SAFE_HISTORY: "Stake history",
  UNSAFE_HISTORY: "Unstake history",
  UNBLOCK: "Unblock",
  BLOCKING: "Release",
  IN: "in",
  SAFE: "Stake",
  TOTAL_RANKING_BONUS: "Total ranking bonus",
  BALANCE_IS_NOT_AVAILABLE: "Balance is not available",

  //KEN
  REPAY_IMMEDIATELY: "Repay immediately",
  BLOCK: "Block",
  ANNOUNCEMENTS: "Announcements",
  DONATE: "Donate",
  DONATE_TO_POOLS_HOLDER: "Donate to pools holder",
  DONATE_TO_POOLS_REWARD: "Donate to pools reward",
  DONATE_TO_POOLS_PROMOTION: "Donate to pools promotion",
  DONATE_TO_AFFILIATE: "Donate to affiliates",
  TOTAL_STAKE: "Total",
  TOTAL_DONATE: "Total donate",
  CONFIRM: "Confirm",
  BACK: "Back",
  I_HAVE_READ_AND_AGREE_TO_THE: "I have read and agree to the",
  TERM_OF_SERVICES_AND_DISCLAIMER: "TERM OF SERVICES AND DISCLAIMER",
  PLEASER_CHECK_TOS:
    "Please read and agree to the TERM OF SERVICES AND DISCLAIMER",
  RELEASE_PROGRESS: "Release progress",
  AGENCY: "agency",
  STAKE_NEW: "Stake",
  TRANSFER: "Transfer",
  MAXIMUM_EARNING: "Estimate Maximum earning",
  CONFIRM_FOR_UNSTAKE: "Confirming for Unstake",
  CONFIRM_FOR_UNSTAKE_SUB: "Are you sure you want to unstake",
  CONFIRM_FOR_UNSTAKE_SUB_1: "You have",
  CONFIRM_FOR_UNSTAKE_SUB_2:
    "more days to get an extra special 20% unstake bonus during the 12 month holding period",
  CONFIRM_FOR_UNSTAKE_SUB_3:
    "Make sure you really want to continue as you will be charged 10% of the fee",
  CONFIRM_FOR_UNSTAKE_SUB_4: "Congratulations!",
  CONFIRM_FOR_UNSTAKE_SUB_5: "You will get an extra special 20% unstake bonus.",
  ORIGINAL_AMOUNT: "Original amount",
  POOL_DISTRIBUTION: "Pool distribution",
  RELEASE: "Release",
  POOL_HOLDER_DES:
    "With the initial amount you invest in SAFELuS, the amount will be deducted 5% of the capital to put into Pools Holder. This fund will be accumulated into rewards, then divided equally among all ID participating in STAKING.",
  POOL_REWARD_DES:
    "Share equally for the total number of Agency members present in POOLS REWARD, the sharing cycle is 30 days (09:00 AM on the first day of the month).",
  POOL_PROMOTION_DES:
    "The amount deducted from the investment capital will be deducted to bring Pools Brand Ambassador as 1%, this bonus will also be paid in 30 days but with the important condition that only promoted members will be rewarded.",
  POOL_HOLDER_CONDITION:
    "Direct commission is received provided that you have 1 account directly tied to the referral code reaching an investment amount of 1000 USD or more (i.e. when your affiliate member achieves AGENCY rank). For each additional account you will receive an additional commission of 1 floor.",
  AFFILIATE_DES:
    "Direct referral commission is received when you are a member with good skills in running the market, which is usually for that part of you. Your reward will be 3% of the total amount of successful affiliates you invest and supported by the Foundation team by 15% (This 15% is also calculated on the total investment capital of that member). ",
  WELCOME_TO: "Welcome to",
  SAFE_1: "SAFE",
  LUS: "LuS",
  BANNER_SUB_TITLE:
    "SAFELuS is a fair decentralized blockchain protocol for strategic holders in the community of LuS TOKEN. This is a self-reinforcing protocol and support for SAFELuS continue to grow because of its fairness.",
  FLOW_SAFE_LUS:
    "SafeLuS donates 10% in each Staking transaction and provides 4 main functions (called SAFE POOLS)",
  FLOW_SAFE_LUS_2:
    "Pools Holder, Pools Reward, Pools Promotion and Affiliate specifically as follows:",
  MARKET_CAP_SAFELUS: "Market Cap SafeLuS",
  HOLDERS: "Holders",
  TOTAL_STAKED: "Total staked",
  TOTAL_SHARE_POOLS: "Total Share Pools",
  POOL_HOLDER: "Pools Holder",
  POOL_HOLDER_SUB:
    "Distributed according to LuS Token holding ratio of Staking IDs (one account can have multiple staking IDs), sharing cycle is 7 days (Monday 09:00AM).",
  POOL_REWARD: "Pools Reward",
  POOL_REWARD_SUB:
    "Share equally for the total number of Agency members present in POOLS REWARD, the sharing cycle is 30 days (09:00 AM on the first day of the month).",
  POOL_BRAND_AMBASSADOR: "Pools Brand Ambassador",
  POOL_BRAND_AMBASSADOR_SUB:
    "Share for elite market development members, according to Ranking ratio, sharing cycle is 30 days (09:00AM on the 15th of the month)",
  CONNECT_WALLET: "Connect Wallet",
  TOTAL_RELEASE: "Total release",
  STAKE: "Stake",
  CONFIRMING_FOR_STAKE: "Confirming for Stake LuS",
  QA: "Q&A",
  SUPPORT_CENTER: "Support center",
  COMPLETED: "Completed",
  AFFILIATE_SUB: "Shared instantly to market development members.",
  AFFILIATE: "Affiliate",
  RELEASED: "Unstake",
  REVENUE: "Revenue",
  REVENUE_LIST: "Revenue list",
  RESERVE_REVENUE: "Reserve revenue",
  STAKE_CONFIRM_MES: "Are you sure you want to stake",
  PENDING: "Waiting"
};
