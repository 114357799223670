import {
  SHOW_CREATE_STAKE_FROM,
  GET_LUS_BALANCE,
  GET_STAKE_HISTORY,
  GET_POOL_LIST,
  GET_STAKING_V1,
  GET_TRANSACTION,
  GET_MY_OPEN_SAFE_HISTORY,
  GET_TOP_HOLDER,
  GET_USER_STATISTIC,
  GET_USER_PROFIT_STATISTIC,
  GET_SAFE_LUS_STATISTIC,
  GET_F1_AFFILIATE,
  GET_LUS_PRICE,
  GET_MY_STAKED,
  GET_MY_UNSAFE_HISTORY,
  GET_SETTING,
  GET_TOP_UP_SETTING,
} from "../constants";

const initialState = {
  showCreateStakeForm: false,
  lusPrice: 0,
  lusBalance: 0,
  lusMBalance: 0,
  stakeHistory: null,
  poolList: null,
  stakingV1: null,
  transactions: null,
  mySafeHistory: null,
  myUnSafeHistory: null,
  topHolder: null,
  myStatistic: null,
  userProfitStatistic: {},
  safeLusStatistic: null,
  f1Affiliates: null,
  myUSDTotalStakingAmount: 0,
  myTotalStakingAmount: 0,
  myTotalEstimatedBalance: 0,
  safeLusSetting: null,
  lusTokenStatistic: {},
  topUpSetting: null,
};

export const SafeLusReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_TOP_UP_SETTING:
      return {
        ...state,
        topUpSetting: payload,
      };
    case GET_SETTING:
      return { ...state, safeLusSetting: payload };
    case GET_MY_STAKED:
      return { ...state, ...payload };
    case GET_LUS_PRICE:
      return {
        ...state,
        ...payload,
      };
    case GET_F1_AFFILIATE:
      return { ...state, f1Affiliates: payload };
    case GET_SAFE_LUS_STATISTIC:
      return { ...state, safeLusStatistic: payload };
    case GET_USER_PROFIT_STATISTIC:
      return { ...state, userProfitStatistic: payload };
    case GET_USER_STATISTIC:
      return { ...state, myStatistic: payload };
    case GET_TOP_HOLDER:
      return { ...state, topHolder: payload };
    case GET_MY_OPEN_SAFE_HISTORY:
      return { ...state, mySafeHistory: payload };
    case GET_MY_UNSAFE_HISTORY:
      return { ...state, myUnSafeHistory: payload };
    case GET_TRANSACTION:
      return { ...state, transactions: payload };
    case GET_STAKING_V1:
      return { ...state, stakingV1: payload };
    case GET_POOL_LIST:
      return { ...state, poolList: payload };
    case GET_STAKE_HISTORY:
      return {
        ...state,
        stakeHistory: payload,
      };
    case GET_LUS_BALANCE:
      return {
        ...state,
        ...payload,
      };
    case SHOW_CREATE_STAKE_FROM:
      return { ...state, showCreateStakeForm: payload };
    default:
      return { ...state };
  }
};
