import { Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { LUS_TOKEN } from "../constants";
import { formatLuS, formatUSD } from "../settings/format";

function LusStatistic() {
  const { safeLus } = useSelector((state) => state);
  const { lusTokenStatistic } = safeLus;

  return (
    <>
      <div className="statistic hide-mobile" translate="no">
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="h5"
            style={{ display: "flex", alignItems: "center" }}
          >
            {LUS_TOKEN} / USDT
          </Typography>
        </div>
        <div>
          <div>
            <Typography className="green-color" variant="h5">
              {formatUSD(lusTokenStatistic.lastPrice)}
            </Typography>
          </div>
          <div>${formatUSD(lusTokenStatistic.lastPriceUsd)}</div>
        </div>
        <div>
          <div className="key">Change (24h)</div>
          <div
            className={
              lusTokenStatistic.priceChange ||
              !lusTokenStatistic.priceChange >= 0
                ? "value green-color text-bold"
                : "value color-EE220C text-bold"
            }
          >
            {(lusTokenStatistic.priceChange >= 0 ? "+" : "-") +
              formatUSD(lusTokenStatistic.priceChange)}{" "}
            {lusTokenStatistic.priceChange >= 0
              ? "+" + formatLuS(lusTokenStatistic.priceChangePercent)
              : "-" + formatLuS(lusTokenStatistic.priceChangePercent)}
            %
          </div>
        </div>
        <div>
          <div className="key">High (24h)</div>
          <div className="value"> {formatUSD(lusTokenStatistic.high)}</div>
        </div>
        <div>
          <div className="key">Low (24h)</div>
          <div className="value"> {formatUSD(lusTokenStatistic.low)}</div>
        </div>
        {/* <div>
          <div className="key">Volume 24h (USDT)</div>
          <div className="value">
            {" "}
            {formatUSD(lusTokenStatistic.quoteVolume)}
          </div>
        </div> */}
        <div>
          <a
            href="https://spexchange.io/page/convert"
            target="_blank"
            rel="noreferrer"
          >
            <Button>TRADE NOW</Button>
          </a>
        </div>
      </div>
      <div
        className="statistic show-mobile mobile-br-0"
        style={{
          padding: "10px 20px",
        }}
      >
        <Typography
          variant="h6"
          style={{ textAlign: "center", marginBottom: 10 }}
        >
          LuS/USDT
        </Typography>
        <Grid container>
          <Grid item xs={6}>
            <div>
              <Typography className="green-color mb-10" variant="h4">
                {formatUSD(lusTokenStatistic.lastPrice)}
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
              }}
            >
              <div>${formatUSD(lusTokenStatistic.lastPriceUsd)}</div>
              <div
                className={
                  lusTokenStatistic.priceChange >= 0
                    ? "value green-color text-bold"
                    : "value color-secondary text-bold"
                }
                style={{
                  marginLeft: 10,
                }}
              >
                {lusTokenStatistic.priceChange >= 0
                  ? "+" + formatLuS(lusTokenStatistic.priceChangePercent)
                  : "-" + formatLuS(lusTokenStatistic.priceChangePercent)}
                %
              </div>
            </div>
          </Grid>
          <Grid item xs={6}>
            <Grid container align="right">
              <Grid item xs={5}>
                <div className="key">High</div>
                <div className="value">{formatUSD(lusTokenStatistic.high)}</div>
              </Grid>
              <Grid item xs={7}>
                <div className="key">Volume LuS</div>
                <div className="value">
                  {formatLuS(lusTokenStatistic.volume)}
                </div>
              </Grid>
            </Grid>
            <Grid container align="right">
              <Grid item xs={5}>
                <div className="key">Low</div>
                <div className="value"> {formatUSD(lusTokenStatistic.low)}</div>
              </Grid>
              <Grid item xs={7}>
                <div className="key">Volume USDT</div>
                <div className="value">
                  {formatLuS(lusTokenStatistic.quoteVolume)}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default LusStatistic;
