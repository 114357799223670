import { Container, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import UserMenu from "../components/UserMenu";
import MyStakeHistory from "../components/my-safe/MyStakeHistory";
import MyUnsafeHistory from "../components/my-safe/MyUnsafeHistory";
import MyPoolHistory from "../components/my-safe/MyPoolHolderHistory";
import { useDispatch, useSelector } from "react-redux";
import {
  _getMyOpenStake,
  _getUserProfitStatistic,
  _getUserStatistic,
} from "../actions/safeLusActions";
import { formatLuS } from "../settings/format";
import { LUS_TOKEN } from "../constants";

function MySafe() {
  const [currentTab, setCurrentTab] = useState(0);
  const { safeLus, setting } = useSelector((state) => state);
  let { myStatistic, userProfitStatistic, lusPrice, mySafeHistory } = safeLus;
  const dispatch = useDispatch();
  const { library } = setting;

  useEffect(() => {
    dispatch(_getUserStatistic());
    dispatch(_getUserProfitStatistic());
    dispatch(_getMyOpenStake(1, 10));
  }, [dispatch]);

  const menus = [
    {
      title: library.SAFE_HISTORY,
      index: 0,
      component: <MyStakeHistory />,
    },
    // {
    //   title: library.UNSAFE_HISTORY,
    //   index: 1,
    //   component: <MyUnsafeHistory />,
    // },
    {
      title: library.DAILY_RELEASE,
      index: 1,
      component: <MyPoolHistory type="DAILY_RELEASE" />,
    },
    {
      title: library.RECEIVE_POOLS_HOLDER,
      index: 2,
      component: <MyPoolHistory type="HOLDER_COMMISSION" />,
    },
    {
      title: library.RECEIVE_POOLS_REWARD,
      index: 3,
      component: <MyPoolHistory type="REWARD" />,
    },
    {
      title: library.RECEIVE_POOLS_PROMOTION,
      index: 4,
      component: <MyPoolHistory type="BA_REWARD" />,
    },
    {
      title: library.RECEIVE_EVENTS,
      index: 5,
    },
  ];

  userProfitStatistic = userProfitStatistic ? userProfitStatistic : {};

  let renderDailyPnl = 0;
  let renderDailyPercent = 0;
  let dailyPnlClass = "color-white";
  let renderTotalPnl = 0;
  let renderTotalPercent = 0;
  let dailyTotalPnlClass = "color-white";
  if (userProfitStatistic) {
    const { dailyPnl, dailyPnlPercent, totalPnlPercent, totalPnl } =
      userProfitStatistic;
    if (dailyPnl > 0) {
      renderDailyPnl = "+" + formatLuS(dailyPnl);
      renderDailyPercent = "+" + formatLuS(dailyPnlPercent);
      dailyPnlClass = "color-primary";
    }
    if (renderTotalPnl > 0) {
      dailyTotalPnlClass = "color-primary";
      renderTotalPnl = "+" + formatLuS(totalPnl);
      renderTotalPercent = "+" + formatLuS(totalPnlPercent);
    }
    // if (totalPnl < 0) {
    //   renderTotalPnl = "-" + formatLuS(totalPnl);
    //   renderTotalPercent = "-" + formatLuS(totalPnlPercent);
    //   dailyTotalPnlClass = "color-secondary";
    // }
  }

  return (
    <div>
      <UserMenu />
      {myStatistic && mySafeHistory && (
        <Container>
          <div className="bg-content">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Grid
                  container
                  className="gray-bg pt-20 pb-20 text-center mobile-br-0 br-5"
                >
                  <Grid
                    item
                    xs={6}
                    style={{
                      borderRight: "1px solid rgba(255, 255, 255, 0.12)",
                    }}
                  >
                    <Typography className="mb-20 opacity-06">
                      {library.MY_STAKED}
                    </Typography>
                    <Typography variant="h5">
                      {formatLuS(mySafeHistory.meta.totalStaked)} {LUS_TOKEN}
                    </Typography>
                    <Typography variant="body1">
                      {formatLuS(mySafeHistory.meta.totalUsdStaked)}
                      <small>$</small>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="mb-20 opacity-06">
                      {library.ESTIMATED_BALANCE}
                    </Typography>
                    <Typography variant="h5">
                      {formatLuS(
                        mySafeHistory.meta.totalStaked + myStatistic.totalEarned
                      )}{" "}
                      {LUS_TOKEN}
                    </Typography>
                    <Typography variant="body1">
                      &#8776;{" "}
                      {formatLuS(
                        (mySafeHistory.meta.totalStaked +
                          myStatistic.totalEarned) *
                          lusPrice
                      )}
                      <small>$</small>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid item xs={12} md={6}>
                <Grid
                  container
                  className="gray-bg pt-20 pb-20 text-center mobile-br-0 br-5"
                >
                  <Grid
                    item
                    xs={6}
                    style={{
                      borderRight: "1px solid rgba(255, 255, 255, 0.12)",
                    }}
                  >
                    <Typography className="mb-20 opacity-06">
                      {library.DAILY} PnL
                    </Typography>
                    <Typography variant="h5" className={dailyPnlClass}>
                      {renderDailyPnl}
                      <small>$</small>
                    </Typography>
                    <Typography variant="body1" className={dailyPnlClass}>
                      {renderDailyPercent}%
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className="mb-20 opacity-06">
                      {library.MONTHLY} PnL
                    </Typography>
                    <Typography variant="h5" className={dailyTotalPnlClass}>
                      {renderTotalPnl}
                      <small>$</small>
                    </Typography>
                    <Typography variant="body1" className={dailyTotalPnlClass}>
                      {renderTotalPercent}%
                    </Typography>
                  </Grid>
                </Grid>
              </Grid> */}
            </Grid>
            <div className="mt-30 mb-20 dashboard-option">
              {menus.map((item, index) => (
                <div
                  key={index}
                  className={currentTab === item.index ? "active" : ""}
                  onClick={() => setCurrentTab(item.index)}
                >
                  {item.title}
                </div>
              ))}
            </div>
            {menus[currentTab].component}
          </div>
        </Container>
      )}
    </div>
  );
}

export default MySafe;
