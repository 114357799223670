import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SHOW_CREATE_STAKE_FROM } from "../constants";
import { Avatar, Fab } from "@material-ui/core";

function Toolbar() {
  const dispatch = useDispatch();
  const { setting } = useSelector((state) => state);
  const { library } = setting;

  return (
    <div id="toolbar" translate="no">
      <Fab
        variant="extended"
        size="small"
        color="primary"
        aria-label="add"
        onClick={() =>
          dispatch({
            type: SHOW_CREATE_STAKE_FROM,
            payload: true,
          })
        }
        style={{
          position: "fixed",
          right: 30,
          bottom: 60,
          width: 150,
          height: 50,
          borderRadius: 30,
          transition: "all 0.2s ease-in-out",
          backgroundColor: "#1d2833",
          zIndex: 3,
          whiteSpace: "nowrap",
          fontWeight: "bold",
          display: "flex",
          justifyContent: "space-between",
          padding: 10,
        }}
      >
        {library.SAFE_NOW}
        <Avatar src="/images/kisspng-rocket-league-clip-art-rocket-5a809c4f2b5ef3.4976906615183780631777.png" />
      </Fab>
    </div>
  );
}

export default Toolbar;
