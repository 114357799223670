import { Grid } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { BA_REWARD, HOLDER, REWARD } from "../constants";
import Pool from "./Pool";

function TotalPoor() {
  const { safeLus, setting } = useSelector((state) => state);
  const { poolList } = safeLus;
  const { library } = setting;

  return (
    poolList && (
      <Grid className="mt-20" container spacing={2}>
        <Grid xs={12} md={4} item>
          <Pool
            type={HOLDER}
            title={library.POOLS_HOLDER + " (7 " + library.DAYS + ")"}
            content={
              <div className="text-center font-size-08">
                {library.POOL_HOLDER_DES}
              </div>
            }
            image="/images/icon-poolsholder.png"
          />
        </Grid>
        <Grid xs={12} md={4} item>
          <Pool
            type={REWARD}
            title={library.POOLS_REWARD + " (30 " + library.DAYS + ")"}
            content={
              <div className="text-center font-size-08">
                {library.POOL_REWARD_DES}
              </div>
            }
            image="/images/icon-poolsreward.png"
          />
        </Grid>
        <Grid xs={12} md={4} item>
          <Pool
            type={BA_REWARD}
            title={library.POOLS_PROMOTION + " (30 " + library.DAYS + ")"}
            content={
              <div className="text-center font-size-08">
                {library.POOL_PROMOTION_DES}
              </div>
            }
            image="/images/icon-poolspromotion.png"
          />
        </Grid>
      </Grid>
    )
  );
}

export default TotalPoor;
