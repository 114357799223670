import {
  Avatar,
  Button,
  ClickAwayListener,
  Collapse,
  Container,
  Divider,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  SwipeableDrawer,
} from "@material-ui/core";
import {
  ArrowDropDown,
  ExpandLess,
  ExpandMore,
  Menu,
  Person,
} from "@material-ui/icons";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import {
  ScreenSize,
  SHOW_CREATE_STAKE_FROM,
  supportCenterMenu,
} from "../constants";
import "../style/UserMenu.scss";
import Toolbar from "./Toolbar";
import logo from "../images/logo.png";
import { logout } from "../utils/auth";
import HeadsetMicIcon from "@material-ui/icons/HeadsetMic";

function UserMenu() {
  const anchorRef = useRef(null);
  const history = useHistory();
  const { setting } = useSelector((state) => state);
  const { library, viewMode, userData } = setting;
  const [openDrawer, setOpenDrawer] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [anchor, setAnchor] = useState(null);
  const dispatch = useDispatch();

  const menus = [
    {
      title: library.DASHBOARD,
      link: "dashboard",
    },
    {
      title: library.my_safe,
      link: "my-safe",
    },
    {
      title: library.affiliate,
      link: "affiliate",
    },
  ];

  const handleToggle = (e, key) => {
    setOpen(key);
    setAnchor(e.currentTarget);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <div className="user-menu">
        <Container>
          <div className="d-flex-cbt w-100pc">
            {/* {viewMode === ScreenSize.Mobile.value ||
            viewMode === ScreenSize.Tablet.value ? ( */}
            {/* <Avatar alt="SafeLuS" src={logo} sizes="large" /> */}
            {/* ) : null} */}
            <div
              className="d-flex-cs logo"
              onClick={() => history.push("/dashboard")}
              style={{ cursor: "pointer" }}
            >
              {/* {viewMode !== ScreenSize.Mobile.value &&
              viewMode !== ScreenSize.Tablet.value ? ( */}
              <Avatar
                alt="SafeLuS"
                src={logo}
                sizes="large"
                style={{ marginRight: 5 }}
              />
              {/* ) : null} */}
              <div
                className="logo-text"
                style={{ cursor: "pointer" }}
                translate="no"
                onClick={() => history.push("/dashboard")}
              >
                <label
                  style={{ cursor: "pointer" }}
                  onClick={() => history.push("/dashboard")}
                >
                  SAFE
                </label>
                <span>LuS</span>
              </div>
            </div>
            <div className="d-flex-ce menu">
              {viewMode !== ScreenSize.Mobile.value ? (
                <>
                  <Button
                    className="circular min-w-150 btn-bg-gradient text-bold text-white"
                    onClick={() =>
                      dispatch({
                        type: SHOW_CREATE_STAKE_FROM,
                        payload: true,
                      })
                    }
                    translate="no"
                  >
                    {library.STAKE_NOW.toUpperCase()}
                  </Button>
                  <>
                    <Button
                      className="text-white"
                      // endIcon={<ArrowDropDown className="opacity-06" />}
                      onClick={(e) => handleToggle(e, "support")}
                      // onClick={() =>
                      //   window.open(`https://support.safelus.finance/`)
                      // }
                    >
                      {/* {library.SUPPORT_CENTER} */}
                      <HeadsetMicIcon />
                    </Button>
                    <Popper
                      open={open === "support"}
                      anchorEl={anchor}
                      transition
                      disablePortal
                      style={{ zIndex: 100 }}
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin:
                              placement === "bottom"
                                ? "center top"
                                : "center bottom",
                          }}
                        >
                          <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                              <MenuList
                                autoFocusItem={open}
                                onKeyDown={handleListKeyDown}
                              >
                                {supportCenterMenu.map((item, index) => (
                                  <MenuItem
                                    onClick={() => window.open(item.url)}
                                    key={index}
                                  >
                                    {item.label}
                                  </MenuItem>
                                ))}
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </>
                  <span className="opacity-04 mr-20">|</span>
                  {userData ? (
                    <>
                      <Button
                        className="text-white"
                        startIcon={<Person />}
                        endIcon={<ArrowDropDown className="opacity-06" />}
                        onClick={(e) => handleToggle(e, "user")}
                      >
                        Hi, {userData.displayName}
                      </Button>
                      <Popper
                        open={open === "user"}
                        anchorEl={anchor}
                        transition
                        disablePortal
                        style={{ zIndex: 100 }}
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin:
                                placement === "bottom"
                                  ? "center top"
                                  : "center bottom",
                            }}
                          >
                            <Paper>
                              <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                  autoFocusItem={open}
                                  onKeyDown={handleListKeyDown}
                                >
                                  {menus.map((item, index) => (
                                    <MenuItem
                                      key={index}
                                      onClick={() =>
                                        history.push(`/${item.link}`)
                                      }
                                    >
                                      {item.title}
                                    </MenuItem>
                                  ))}
                                  <MenuItem onClick={logout}>
                                    {library.LOGOUT}
                                  </MenuItem>
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </>
                  ) : null}
                </>
              ) : null}

              {viewMode === ScreenSize.Mobile.value ? (
                <IconButton onClick={() => setOpenDrawer(true)}>
                  <Menu />
                </IconButton>
              ) : null}
            </div>
          </div>
        </Container>
      </div>
      {viewMode === ScreenSize.Mobile.value ? (
        <SwipeableDrawer
          anchor={"right"}
          open={openDrawer}
          onOpen={() => setOpenDrawer(true)}
          onClose={() => setOpenDrawer(false)}
          className="user-menu-drawer"
        >
          <div style={{ padding: "20px 20px", background: "rgba(0,0,0,0.5)" }}>
            <Button
              className="text-white"
              startIcon={<Person />}
              onClick={(e) => handleToggle(e)}
            >
              Hi, {userData && userData.displayName}
            </Button>
          </div>
          <RenderMenu menus={menus} />
        </SwipeableDrawer>
      ) : null}
      <Toolbar />
    </>
  );
}

function RenderMenu({ menus }) {
  const history = useHistory();
  const { setting } = useSelector((state) => state);
  const { library } = setting;
  const [activeCollapse, setActiveCollapse] = useState(false);

  return (
    <>
      {/* <Link to="/dashboard">{library.DASHBOARD}</Link> */}
      {menus.map((item, index) => (
        <React.Fragment key={index}>
          <Link
            to={item.link}
            className={
              history.location.pathname.replace("/", "") === item.link
                ? "active"
                : ""
            }
          >
            <div>{item.title}</div>
          </Link>
          <Divider />
        </React.Fragment>
      ))}
      <Link onClick={() => setActiveCollapse(!activeCollapse)} to="#">
        Support Center
        {activeCollapse ? <ExpandLess /> : <ExpandMore />}
      </Link>
      <Collapse
        in={activeCollapse}
        timeout="auto"
        unmountOnExit
        style={{
          opacity: 0.5,
          backgroundColor: "rgba(0, 0, 0, 0.3)",
          color: "#fff",
        }}
      >
        {supportCenterMenu.map((menu, subIndex) => (
          <MenuItem onClick={() => window.open(menu.url)} key={subIndex}>
            {menu.label}
          </MenuItem>
        ))}
      </Collapse>
      <Divider />
      <Link onClick={logout} to="#">{library.LOGOUT}</Link>
    </>
  );
}

export default UserMenu;
