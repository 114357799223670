/* eslint-disable jsx-a11y/alt-text */
import { Drawer, Typography, Grid, Button, Paper } from "@material-ui/core";
import React from "react";
import HighlightOffSharpIcon from "@material-ui/icons/HighlightOffSharp";
import { useDispatch, useSelector } from "react-redux";
import { formatLuS, formatUSD } from "../settings/format";
import { LUS_TOKEN, SHOW_CREATE_STAKE_FROM } from "../constants";

function CustomAds({ showAds, _close }) {
  const { safeLus, setting } = useSelector((state) => state);
  const { myStatistic, lusPrice, topUpSetting, mySafeHistory } = safeLus;
  const { library } = setting;
  const dispatch = useDispatch();

  let topUp = null;
  if (topUpSetting && myStatistic) {
    if (myStatistic.pendingBaReward > 0) {
      topUp = topUpSetting.filter((item) => item.rank === myStatistic.rank)[0];
    }
  }

  const _handleClick = () => {
    _close();
    dispatch({
      type: SHOW_CREATE_STAKE_FROM,
      payload: true,
    });
  };

  return (
    topUp && (
      <Drawer
        anchor={"left"}
        open={showAds}
        className={"custom-modal-vk"}
        onClose={_close}
      >
        <Paper className="top-up" square elevation={3}>
          <div id="wrapper" className="hide-mobile">
            <div id="left"></div>
            <div id="right"></div>
          </div>
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              alignItems: "center",
              position: "absolute",
              color: "#fff",
            }}
          >
            <Grid container alignItems="center">
              <Grid item xs={12} sm={6}>
                {mySafeHistory &&
                mySafeHistory.meta.totalUsdStaked >= topUp.minStaked ? (
                  <>
                    <Typography>Congratulation!!!</Typography>
                    <Typography variant="h4">
                      {myStatistic && formatLuS(myStatistic.pendingBaReward)}{" "}
                      {LUS_TOKEN}
                    </Typography>
                    <Typography style={{ fontStyle: "italic", opacity: 0.6 }}>
                      will be received on the next release
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography style={{ textAlign: "left" }}>
                      <span style={{ opacity: 0.6 }}>Stake up to</span>{" "}
                      <span style={{ fontSize: "1.3em" }}>
                        <small>$</small>
                        {formatUSD(topUp ? topUp.minStaked : 0)}
                      </span>{" "}
                      <small style={{ opacity: 0.6 }}>
                        ~ {formatUSD(topUp ? topUp.minStaked / lusPrice : 0)}{" "}
                        LuS
                      </small>
                    </Typography>
                    <div className="mb-30 mt-30">
                      <Typography style={{ fontStyle: "italic", opacity: 0.6 }}>
                        To release
                      </Typography>
                      <Typography variant="h4">
                        {myStatistic && formatLuS(myStatistic.pendingBaReward)}{" "}
                        {LUS_TOKEN}
                      </Typography>
                    </div>
                    <Button
                      className="submit-btn"
                      variant="outlined"
                      style={{
                        color: "#fff",
                        border: "1px solid #fff",
                        borderRadius: 20,
                        width: 125,
                      }}
                      onClick={_handleClick}
                    >
                      {library.STAKE_NOW.toUpperCase()}
                    </Button>
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={6} style={{ textAlign: "right" }}>
                <HighlightOffSharpIcon
                  onClick={_close}
                  style={{
                    fill: "#999",
                    position: "absolute",
                    top: 10,
                    right: 10,
                    cursor: "pointer",
                  }}
                />
                <img
                  src="/images/kisspng-rocket-league-clip-art-rocket-5a809c4f2b5ef3.4976906615183780631777.png"
                  style={{ width: 250 }}
                />
              </Grid>
            </Grid>
          </div>
        </Paper>
      </Drawer>
    )
  );
}

export default CustomAds;
