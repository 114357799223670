import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { _getF1Affiliate } from "../../actions/safeLusActions";
import { LUS_TOKEN, RANK_LEVEL } from "../../constants";
import { formatLuS, formatUSD } from "../../settings/format";

function Members() {
  const { safeLus, setting } = useSelector((state) => state);
  const { f1Affiliates } = safeLus;
  const { library } = setting;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(_getF1Affiliate(page, pageSize));
  }, [dispatch, page, pageSize]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value));
    setPage(1);
  };

  return (
    <div className="gray-bg p-20 br-5 mobile-br-0 mobile-p-0">
      <TableContainer>
        <Table size="small" aria-label="a dense table">
          <TableHead className="white-color hide-mobile opacity-08">
            <TableRow>
              <TableCell>{library.ACCOUNT}</TableCell>
              <TableCell>
                {library.STAKED} ({LUS_TOKEN})
              </TableCell>
              <TableCell>
                {library.TOTAL_REVENUE} ({LUS_TOKEN})
              </TableCell>
              <TableCell>{library.LEVEL}</TableCell>
              <TableCell>{library.RANK}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {f1Affiliates ? (
              f1Affiliates.items.length > 0 ? (
                f1Affiliates.items.map((row, index) => (
                  <React.Fragment key={index}>
                    <TableRow className="hide-mobile">
                      <TableCell className="text-bold">
                        {row.displayName}
                      </TableCell>
                      <TableCell className="text-bold">
                        {formatLuS(row.staked)} ({formatUSD(row.usdStaked)}$)
                      </TableCell>
                      <TableCell className="text-bold">
                        {formatLuS(row.totalRevenue)} (
                        {formatUSD(row.totalUsdRevenue)}$)
                      </TableCell>
                      <TableCell>{RANK_LEVEL[row.level]}</TableCell>
                      <TableCell>{RANK_LEVEL[row.rank]}</TableCell>
                    </TableRow>
                    <TableRow className="show-mobile show-mobile-table-row">
                      <TableCell colSpan={4}>
                        <div className="d-flex-sbt">
                          <div>
                            <div>{library.ACCOUNT}</div>
                            <div>{library.LEVEL}</div>
                            <div>{library.RANK}</div>
                            <div>{library.STAKED}</div>
                            <div>&nbsp;</div>
                            <div>{library.TOTAL_REVENUE}</div>
                            <div>&nbsp;</div>
                          </div>
                          <div
                            // className="text-bold"
                            style={{ textAlign: "right" }}
                          >
                            <div>{row.displayName}</div>
                            <div>{RANK_LEVEL[row.level]}</div>
                            <div>{RANK_LEVEL[row.rank]}</div>
                            <div>
                              <div>
                                {formatLuS(row.staked)} {LUS_TOKEN}
                              </div>
                              <div>{formatUSD(row.usdStaked)}$</div>
                            </div>
                            <div>
                              <div>
                                {formatLuS(row.totalRevenue)} {LUS_TOKEN}
                              </div>
                              <div>{formatUSD(row.totalUsdRevenue)}$</div>
                            </div>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} className="text-center">
                    {library.NO_RECORDS_FOUND}
                  </TableCell>
                </TableRow>
              )
            ) : (
              <TableRow>
                <TableCell colSpan={4}>
                  <div style={{ textAlign: "center" }}>
                    <CircularProgress style={{ margin: "auto" }} />
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {f1Affiliates && (
          <TablePagination
            component="div"
            count={f1Affiliates.itemCount}
            page={page - 1}
            onChangePage={handleChangePage}
            rowsPerPage={pageSize}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </TableContainer>
    </div>
  );
}

export default Members;
