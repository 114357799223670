export const isProduction = window.location.hostname === "safelus.finance";
export const isBeta = window.location.hostname === "beta.safelus.finance";
export const isDev = window.location.hostname === "lusv2.spdev.co";
export const S3_URL = "https://s3.spwallet.org";
export const API =
  isProduction || isBeta
    ? "https://api.spwallet.org"
    : "https://spwalletapi.spdev.co";
export const ACCESS_TOKEN_KEY = "CBt3gpbqeMdWPNG1";
export const DEVICE_KEY = "uU5tEUmAgvBWArsv";
export const SCOPES_KEY = "AhBcmvr1EkMdPnL5";
export const RETURN_SAFE_LUS_KEY =
  "XjBWmk6sBBUYdbb6smww1TMyLSC0YrEq8vCpcTSG1xcT7RDCpZyfDZzLedqNSaA8";

const SPWALLET_URLs = {
  "safelus.finance": "https://spwallet.org",
  "beta.safelus.finance": "https://beta.spwallet.org",
  "lusv2.spdev.co": "https://spwallet.spdev.co",
  localhost: "http://localhost:3000",
};

export const API_GET_MARKET_LUS =
  isProduction || isBeta
    ? "https://api.spexchange.io/trade-service/market?symbol=LUSUSDT"
    : "https://spexchangeapi.spdev.co/trade-service/market?symbol=LUSUSDT";

export const SPWALLET_URL = SPWALLET_URLs[window.location.hostname];
