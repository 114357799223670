import React, { useEffect, useRef, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { formatLuS } from "../../settings/format";
import { LUS_TOKEN } from "../../constants";
import {
  _getBalance,
  _getMyOpenStake,
  _getMyTotalStakingAmount,
  _getStakeHistory,
  _getUserStatistic,
} from "../../actions/safeLusActions";
import {
  Button,
  CircularProgress,
  Container,
  Drawer,
  Grid,
  TablePagination,
  Typography,
} from "@material-ui/core";
import { _delete } from "../../utils/api";
import { API_UNSAFE } from "../../settings/apiEndpoint";
import { CustomToast } from "../../actions";
import { Dimmer } from "semantic-ui-react";

export default function MyStakeHistory() {
  const { safeLus, setting } = useSelector((state) => state);
  const { mySafeHistory } = safeLus;
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { library } = setting;
  const [selectedStake, setSelectedStake] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [remainDay, setRemainDay] = useState(0);

  useEffect(() => {
    dispatch(_getMyOpenStake(page, pageSize));
  }, [dispatch, page, pageSize]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value));
    setPage(1);
  };

  const _handleUnsafe = () => {
    const tempId = selectedStake.id;
    setProcessing(true);
    _delete(
      API_UNSAFE + tempId,
      {},
      () => {
        CustomToast("success", library.UNSAFE_SUCCESS + tempId);
        dispatch(_getStakeHistory(1, 10));
        dispatch(_getBalance());
        dispatch(_getMyOpenStake(1, 10));
        dispatch(_getUserStatistic());
        setSelectedStake(null);
        setProcessing(false);
        dispatch(_getMyTotalStakingAmount());
      },
      (error) => {
        CustomToast("error", error.code);
        setProcessing(false);
      }
    );
  };

  useEffect(() => {
    if (selectedStake) {
      const temp = moment().unix() * 1000 - selectedStake.createdDate;
      setRemainDay(365 - parseInt(temp / 86400000));
    }
  }, [selectedStake]);

  return (
    <div
      className="gray-bg pd-20 mobile-br-0 mobile-p-0 br-5"
      style={{ color: "#fff" }}
    >
      <TableContainer>
        <Table size="small" aria-label="a dense table">
          <TableHead className="white-color hide-mobile opacity-08">
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>
                {library.AMOUNT} ({LUS_TOKEN})
              </TableCell>
              <TableCell>{library.TIME}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mySafeHistory ? (
              mySafeHistory.items.length > 0 ? (
                mySafeHistory.items.map((row, index) => (
                  <React.Fragment key={index}>
                    <TableRow className="hide-mobile">
                      <TableCell className="text-bold">#{row.id}</TableCell>
                      <TableCell>{formatLuS(row.currentAmount)}</TableCell>
                      <TableCell>
                        {moment(row.createdDate).format("MM-DD-YYYY HH:mm:ss")}
                      </TableCell>
                      <TableCell align="right">
                        {row.status === "OPEN" ? (
                          <Button
                            onClick={() => setSelectedStake(row)}
                            className="btn-cancel mt-5 mb-5"
                          >
                            {library.UNSAFE}
                          </Button>
                        ) : (
                          <RenderProcess data={row} />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow className="show-mobile show-mobile-table-row">
                      <TableCell colSpan={4}>
                        <div className="d-flex-sbt">
                          <div>
                            <div className="text-bold">
                              {formatLuS(row.initAmount)} {LUS_TOKEN}
                            </div>
                            <div>
                              <small>
                                {moment(row.createdDate).format(
                                  "MM-DD-YYYY HH:mm:ss"
                                )}
                              </small>
                            </div>
                          </div>
                          <div style={{ textAlign: "right" }}>
                            <div>
                              {row.status === "OPEN" && (
                                <Button
                                  onClick={() => setSelectedStake(row)}
                                  className="btn-cancel mt-5 mb-5"
                                  style={{
                                    fontSize: 20,
                                  }}
                                >
                                  {library.UNSAFE}
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                        {row.status !== "OPEN" && <RenderProcess data={row} />}
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} className="text-center">
                    {library.NO_RECORDS_FOUND}
                  </TableCell>
                </TableRow>
              )
            ) : (
              <TableRow>
                <TableCell colSpan={6}>
                  <div style={{ textAlign: "center" }}>
                    <CircularProgress style={{ margin: "auto" }} />
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {mySafeHistory && (
          <TablePagination
            component="div"
            count={mySafeHistory.itemCount}
            page={page - 1}
            onChangePage={handleChangePage}
            rowsPerPage={pageSize}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </TableContainer>
      <Drawer
        anchor={"left"}
        open={selectedStake !== null}
        className={"custom-modal-vk"}
      >
        <Container
          className="gray-bg white-color"
          style={{
            maxWidth: "calc(100vw - 30px)",
            // width: "35vw",
          }}
        >
          {selectedStake ? (
            <div
              style={{
                padding: "30px 30px",
              }}
            >
              <Typography variant={"h6"}>
                {library.CONFIRM_FOR_UNSTAKE}
              </Typography>
              <Typography className="opacity-06 font-size-11 max-w-400">
                {library.CONFIRM_FOR_UNSTAKE_SUB}{" "}
                {formatLuS(selectedStake.initAmount)} LuS?
              </Typography>
              {remainDay > 0 ? (
                <>
                  <Typography className="opacity-06 mt-20 max-w-400">
                    {/* {library.CONFIRM_FOR_UNSTAKE_SUB_1} {formatLuS(remainDay)}{" "}
                    {library.CONFIRM_FOR_UNSTAKE_SUB_2}. */}
                    For UNSTAKE, when you operate, SAFELuS will deduct 10% of
                    the UNSTAKE fee ahead of time and All profits will be
                    recovered by SAFELuS. Pay 25% immediately on the remaining
                    amount, and 75% will pay in 200 days.
                  </Typography>
                  <Typography className="opacity-06 max-w-400 mt-10">
                    {/* {library.CONFIRM_FOR_UNSTAKE_SUB_3}. */}
                    You have {formatLuS(remainDay)} more days, you will not be
                    charged this fee, on the contrary, an additional 10% will be
                    added for users who have long-term commitment to the
                    program.
                  </Typography>
                </>
              ) : (
                <>
                  <Typography className="opacity-06 mt-20 max-w-400">
                    {library.CONFIRM_FOR_UNSTAKE_SUB_4}
                  </Typography>
                  <Typography className="opacity-06  max-w-400">
                    {/* {library.CONFIRM_FOR_UNSTAKE_SUB_5} */}
                    You will not be charged this fee, on the contrary, an
                    additional 10% will be added for users who have long-term
                    commitment to the program.
                  </Typography>
                </>
              )}
              {/* <ul className="mt-10 w-100pc">
                <li className="d-flex-cbt p-10-0 bg-none">
                  <span className=" min-w-130">{library.AMOUNT}</span>
                  <span>
                    {formatLuS(selectedStake.initAmount * 0.9)}{" "}
                    <span className="opacity-04">LuS</span>
                  </span>
                </li>
                <li className="d-flex-cbt p-10-0 bg-none border-top">
                  <span className="min-w-130 ">
                    {library.POOL_DISTRIBUTION}
                  </span>
                  <span>
                    <span className="text-right">
                      -{formatLuS(selectedStake.initAmount * 0.1)}{" "}
                      <span className="opacity-06">LuS</span>
                    </span>
                    <span className="opacity-04 w-100pc text-right">
                      (10% {library.ORIGINAL_AMOUNT})
                    </span>
                  </span>
                </li>
                <li className="d-flex-cbt opacity-06 p-0 min-h-20 bg-none">
                  <span className="font-size-09">
                    {library.DONATE_TO_POOLS_HOLDER}
                  </span>
                  <span className="font-size-09">
                    {formatLuS(selectedStake.initAmount * 0.05)}{" "}
                    <span className="opacity-06">LuS (5%)</span>
                  </span>
                </li>
                <li className="d-flex-cbt opacity-06 p-0 min-h-20 bg-none">
                  <span className="font-size-09">
                    {library.DONATE_TO_POOLS_REWARD}
                  </span>
                  <span className="font-size-09">
                    {formatLuS(selectedStake.initAmount * 0.03)}{" "}
                    <span className="opacity-06">LuS (3%)</span>
                  </span>
                </li>
                <li className="d-flex-cbt opacity-06 p-0 min-h-20 bg-none">
                  <span className="font-size-09">
                    {library.DONATE_TO_POOLS_PROMOTION}
                  </span>
                  <span className="font-size-09">
                    {formatLuS(selectedStake.initAmount * 0.02)}{" "}
                    <span className="opacity-06">LuS (2%)</span>
                  </span>
                </li>
                <li className="d-flex-cbt p-10-0 bg-none border-top mt-10">
                  <span className=" min-w-130 ">{library.UNSAFE}</span>
                  <span>
                    {formatLuS(selectedStake.initAmount * 0.8)}{" "}
                    <span className="opacity-04">LuS</span>
                  </span>
                </li>
                <li className="d-flex-cbt opacity-06 p-0 min-h-20 bg-none">
                  <span className="font-size-09">
                    {library.REPAY_IMMEDIATELY}
                  </span>
                  <span className="font-size-09">
                    {formatLuS(selectedStake.initAmount * 0.8 * 0.25)}{" "}
                    <span className="opacity-06">LuS (25%)</span>
                  </span>
                </li>
                <li className="d-flex-cbt opacity-06 p-0 min-h-20 bg-none">
                  <span className="font-size-09">
                    {library.RELEASE} {selectedStake.initBlockedDays} days
                  </span>
                  <span className="font-size-09">
                    {formatLuS(selectedStake.initAmount * 0.8 * 0.75)}{" "}
                    <span className="opacity-06">LuS (75%)</span>
                  </span>
                </li>
              </ul> */}
              {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={true}
                    // onChange={handleChange}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Primary"
              /> */}
              <Grid container className="mt-30" spacing={2}>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    onClick={() => setSelectedStake(null)}
                    className="btn-cancel"
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    onClick={_handleUnsafe}
                    fullWidth
                    className="btn-submit relative hidden"
                    disabled={processing}
                  >
                    {library.UNSAFE}
                    {processing ? (
                      <Dimmer active>
                        <CircularProgress />
                      </Dimmer>
                    ) : null}
                  </Button>
                </Grid>
              </Grid>
            </div>
          ) : null}
        </Container>
      </Drawer>
    </div>
  );
}

function RenderProcess({ data }) {
  const { setting } = useSelector((state) => state);
  const { library } = setting;
  const [lusValueWidth, setLusValueWidth] = useState(0);
  const [lusContainWidth, setLusContainWidth] = useState(0);
  const [dayValueWidth, setDayValueWidth] = useState(0);
  const [dayContainWidth, setDayContainWidth] = useState(0);
  const lusValueRef = useRef();
  const lusContainRef = useRef();
  const dayValueRef = useRef();
  const dayContainRef = useRef();
  useEffect(() => {
    if (data) {
      if (lusValueRef.current)
        setLusValueWidth(lusValueRef.current.clientWidth);
      if (lusContainRef.current)
        setLusContainWidth(lusContainRef.current.clientWidth);
      if (dayValueRef.current)
        setDayValueWidth(dayValueRef.current.clientWidth);
      if (dayContainRef.current)
        setDayContainWidth(dayContainRef.current.clientWidth);
    }
  }, [data]);
  if (data)
    return (
      <div className="d-flex-cbt release-process-bar">
        {data.status === "RELEASED" ? (
          <div className="lus" style={{ width: "100%" }}>
            <span className="opacity-06  ">{library.COMPLETED}</span>
          </div>
        ) : (
          <>
            <div
              className={
                "days " + (dayContainWidth < dayValueWidth ? "top" : "")
              }
              ref={dayContainRef}
              style={{
                width: `${(data.blockedDays * 100) / 200}%`,
              }}
            >
              <span ref={dayValueRef}>
                {data.blockedDays > 0
                  ? `${data.blockedDays} ${library.DAYS}`
                  : ""}
              </span>
            </div>
            <div
              ref={lusContainRef}
              className={
                "lus" +
                (lusContainWidth < lusValueWidth ? " top" : "") +
                (data.blockedDays === 0 ? " circular" : "")
              }
              style={{
                width: `${((200 - data.blockedDays) * 100) / 200}%`,
              }}
            >
              {data.blockedDays > 0 ? (
                <span ref={lusValueRef}>
                  {formatLuS(data.released)} {LUS_TOKEN}
                </span>
              ) : null}
              {data.blockedDays <= 0 ? (
                <span className="opacity-06  ">{library.COMPLETED}</span>
              ) : (
                ""
              )}
            </div>
          </>
        )}
      </div>
    );
  else return null;
}
