export const API_GET_PROFILE = `/user-service/user/profile`;
export const APT_GET_BALANCE = `/fund-service/fund/list`;
export const API_CREATE_STAKE = `/lus-staking-service/v2/staking`;
export const API_STAKE_HISTORY = `/lus-staking-service/v2/staking/all`;
export const API_GET_POOL_LIST = `/lus-staking-service/v2/pool/list`;
export const API_GET_STAKING_V1 = `/lus-staking-service/v2/staking/open-staking-v1/list`;
export const API_GET_TRANSACTION = `/lus-staking-service/v2/transaction/list`;
export const API_GET_MY_OPEN_SAFE_HISTORY = `/lus-staking-service/v2/staking/list`;
// export const API_GET_TOP_HOLDER = `/lus-user-service/lus-user/v2/top-holder`;
export const API_GET_TOP_HOLDER = `/lus-staking-service/v2/staking/top-holder`;
export const API_GET_USER_STATISTIC = `/lus-user-service/lus-user/v2/statistic`;
export const API_GET_USER_PROFIT_STATISTIC = `/lus-staking-service/v2/staking/profit/statistic`;
export const API_GET_SAFE_LUS_STATISTIC = `/lus-staking-service/v2/staking/public/statistic`;
export const API_GET_F1_AFFILIATE = `/lus-user-service/lus-user/v2/list-f1-affiliate`;
export const API_UNSAFE = `/lus-staking-service/v2/staking?id=`;
export const API_GET_LUS_PRICE = `https://spexchangeapi.spdev.co/trade-service/market?symbol=LUSUSDT`;
export const API_SAFE_LUS_SETTING = `/lus-user-service/lus-user/v2/constant/member-level`;
export const API_GET_TOP_UP_LEVEL = `/lus-staking-service/v2/staking/top-up-level`;
