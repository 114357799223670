import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Affiliate from "./pages/Affiliate";
import Dashboard from "./pages/Dashboard";
import Events from "./pages/Events";
import Homepage from "./pages/Homepage";
import MySafe from "./pages/MySafe";
import Login from "./pages/Login";
import "semantic-ui-css/semantic.min.css";
import "./style/CustomStyle.scss";
import "./style/default.scss";
import { useDispatch, useSelector } from "react-redux";
import { ScreenSize } from "./constants";
import { _getProfile, _updateScreenMode } from "./actions/settingActions";
import { useEffect, useState } from "react";
import CreateStakeForm from "./components/CreateStakeForm";
import {
  _getBalance,
  _getLusPrice,
  _getMyOpenStake,
  _getMyTotalStakingAmount,
  _getSafeLusStatistic,
  _getSetting,
  _getTopHolder,
  _getTopUpSetting,
} from "./actions/safeLusActions";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import "../src/style/Background.scss";
import { isLoggedIn } from "./utils/auth";
import CustomAds from "./components/CustomAds";

function App() {
  const { setting } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { viewMode } = setting;
  const [showAds, setShowAds] = useState(true);

  function handleResize() {
    const viewWidth = window.innerWidth;
    if (viewWidth <= ScreenSize.Mobile.maxWidth) {
      dispatch(_updateScreenMode(ScreenSize.Mobile.value));
      return;
    }
    if (viewWidth <= ScreenSize.Tablet.maxWidth) {
      dispatch(_updateScreenMode(ScreenSize.Tablet.value));
      return;
    }
    dispatch(_updateScreenMode(ScreenSize.Desktop.value));
  }

  useEffect(() => {
    window.addEventListener("resize", () => handleResize());
    handleResize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(_getBalance());
    dispatch(_getSafeLusStatistic());
    dispatch(_getLusPrice());
    dispatch(_getMyTotalStakingAmount());
    dispatch(_getMyOpenStake(1, 10, "OPEN"));
    dispatch(_getSetting());
    dispatch(_getProfile());
    dispatch(_getTopHolder(1, 10));
    dispatch(_getTopUpSetting());
  }, [dispatch]);

  useEffect(() => {
    const interval = setTimeout(() => {
      dispatch(_getLusPrice());
    }, 5000);
    return () => clearInterval(interval);
  }, [dispatch]);

  var array = Array.from(Array(100).keys());

  return (
    <>
      <div
        className={
          "App " + (ScreenSize[viewMode] ? ScreenSize[viewMode].class : "")
        }
      >
        <div>
          {array.map((index) => (
            <div className="circle-container" key={index}>
              <div className="circle"></div>
            </div>
          ))}
        </div>
        {isLoggedIn() ? (
          <Router>
            <Switch>
              <Route path="/events" component={Events} />
              <Route path="/affiliate" component={Affiliate} />
              <Route path="/my-safe" component={MySafe} />
              <Route path="/dashboard" component={Dashboard} />
              <Route path="/login" component={Login} />
              <Route path="*" component={Homepage} />
            </Switch>
          </Router>
        ) : (
          <Router>
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="*" component={Homepage} />
            </Switch>
          </Router>
        )}
        <CreateStakeForm />
        <ToastContainer />
      </div>
      <div
        style={{
          background: `url(${`/images/safe-lus-bg.jpg`})`,
          position: "fixed",
          height: "100vh",
          width: "100vw",
          top: 0,
          left: 0,
          backgroundSize: "cover",
          backgroundPosition: "50% 50%",
        }}
      ></div>
      <CustomAds showAds={showAds} _close={() => setShowAds(false)} />
    </>
  );
}

export default App;
