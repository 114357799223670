import {
  Container,
  Divider,
  Drawer,
  Grid,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { LUS_M_TOKEN, LUS_TOKEN } from "../constants";
import { formatLuS } from "../settings/format";
import HighlightOffSharpIcon from "@material-ui/icons/HighlightOffSharp";

function RevenueList({ showRevenueList, _close }) {
  const { safeLus, setting } = useSelector((state) => state);
  const { myStatistic } = safeLus;
  const { library } = setting;
  
  return (
    <Drawer
      anchor={"left"}
      open={showRevenueList}
      className={"custom-modal-vk"}
      onClose={_close}
    >
      <Container
        className="gray-bg white-color p-20"
        style={{
          width: 300,
        }}
      >
        <HighlightOffSharpIcon className="close-icon" onClick={_close} />
        <Typography variant="h5">{library.REVENUE_LIST}</Typography>
        <Divider className="mt-20 mb-10" />
        <Grid container>
          <Grid item xs={6} className="opacity-06">
            {library.REVENUE}
          </Grid>
          <Grid item xs={6} className="text-right text-bold">
            <small>$</small>
            {myStatistic && formatLuS(myStatistic.totalNewAffiliateUsdRevenue)}
            <br />
            {myStatistic &&
              formatLuS(myStatistic.totalNewAffiliateRevenue)}{" "}
            {LUS_TOKEN}
          </Grid>
        </Grid>
        <Divider className="mt-10 mb-10" />
        <Grid container>
          <Grid item xs={6} className="opacity-06">
            {library.RESERVE_REVENUE}
          </Grid>
          <Grid item xs={6} className="text-right text-bold">
            <small>$</small>
            {myStatistic &&
              formatLuS(myStatistic.totalAffiliateUsdRevenueByLusM)}
            <br />
            {myStatistic &&
              formatLuS(myStatistic.totalAffiliateRevenueByLusM)}{" "}
            {LUS_M_TOKEN}
          </Grid>
        </Grid>
        <Divider className="mt-10 mb-10" />
        <Grid container>
          <Grid item xs={6} className="opacity-06">
            V1 {library.RESERVE_REVENUE.toLowerCase()}
          </Grid>
          <Grid item xs={6} className="text-right text-bold">
            <small>$</small>
            {myStatistic && formatLuS(myStatistic.totalAffiliateUsdRevenueV1)}
            <br />
            {myStatistic && formatLuS(myStatistic.totalAffiliateRevenueV1)}{" "}
            {LUS_TOKEN}
          </Grid>
        </Grid>
      </Container>
    </Drawer>
  );
}

export default RevenueList;
