import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { Dimmer, Loader } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import { useDispatch, useSelector } from "react-redux";
import { _getProfile } from "../actions/settingActions";
import { setAccessToken } from "../utils/auth";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function Login() {
  let query = useQuery();
  let token = query.get("token");
  const dispatch = useDispatch();
  const { setting } = useSelector((state) => state);
  const { userData } = setting;
  const history = useHistory();

  useEffect(() => {
    if (userData) history.push("/dashboard");
  }, [history, userData]);

  useEffect(() => {
    if (!token) {
      history.push("/");
    } else {
      setAccessToken(token);
      dispatch(_getProfile());
    }
  }, [dispatch, history, token]);

  return (
    <Dimmer page active>
      <Loader active />
    </Dimmer>
  );
}

export default Login;
