import { GET_PROFILE } from "../constants";
import { english } from "../languages/english";
import { ScreenSize } from "../constants";
import { UPDATE_SCREEN_MODE } from "./constant";

const initialState = {
  library: english,
  userData: null,
  viewMode: ScreenSize.Desktop.value,
};

export const SettingReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_PROFILE:
      return { ...state, userData: payload };
    case UPDATE_SCREEN_MODE:
      return {
        ...state,
        viewMode: payload,
      };
    default:
      return { ...state };
  }
};
