/* eslint-disable jsx-a11y/alt-text */
import { Button, Container, Grid, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import background from "../images/rocket-bg-1.jpg";
import "../style/home-page.scss";
import reward01 from "../images/chart.png";
import reward02 from "../images/group.png";
import reward03 from "../images/lus-multi.png";
import reward04 from "../images/share.png";
import service01 from "../images/service01.png";
import service02 from "../images/service02.png";
import service03 from "../images/service03.png";
import service04 from "../images/service04.png";
import { useDispatch, useSelector } from "react-redux";
import { formatLuS } from "../settings/format";
import { LUS_TOKEN } from "../constants";
import Header from "../components/header/Header";
import Footer from "../components/Footer";
import { RETURN_SAFE_LUS_KEY, SPWALLET_URL } from "../settings";
import Brands from "../components/homepage/Brands";
import Roadmap from "../components/homepage/Roadmap";
import NewStaking from "../components/homepage/NewStaking";
import HomepageTopHolder from "../components/homepage/TopHolder";
import { _getSafeLusStatistic, _getTopHolder } from "../actions/safeLusActions";

function Homepage() {
  const { safeLus, setting } = useSelector((state) => state);
  const { safeLusStatistic } = safeLus;
  const { library } = setting;
  const dispatch = useDispatch();

  useEffect(() => {
    if (document.getElementsByClassName("App")) {
      document.getElementsByClassName("App")[0].style.padding = 0;
    }
  }, []);

  useEffect(() => {
    dispatch(_getTopHolder(1, 10));
    dispatch(_getSafeLusStatistic());
  }, [dispatch]);

  return (
    <>
      <Header />
      <div id="home-page">
        <Container maxWidth={false}>
          <div className="reward" style={{ background: `url(${background})` }}>
            <div>
              <div style={{ padding: "0px 6rem" }}>
                <p>{library.WELCOME_TO}</p>
                <p className="logo-text" translate="no">
                  <label>{library.SAFE_1}</label>
                  <span>{library.LUS}</span>
                </p>
                <p>{library.BANNER_SUB_TITLE}</p>
                <Button
                  className="circular min-w-200 btn-bg-gradient text-bold text-white"
                  style={{ marginRight: -20 }}
                  onClick={() =>
                    (window.location.href = `${SPWALLET_URL}/login?return=${RETURN_SAFE_LUS_KEY}`)
                  }
                  translate="no"
                >
                  {library.CONNECT_WALLET
                    ? library.CONNECT_WALLET.toUpperCase()
                    : ""}
                </Button>
              </div>
              <Grid container spacing={3} className="mt-70">
                <Grid item xs={3} className="text-center">
                  <img src={reward01} style={{ height: 60 }} />
                  <p className="font-size-13 text-center w-100pc mt-10 text-bold">
                    $
                    {safeLusStatistic
                      ? formatLuS(safeLusStatistic.totalUsdStaked)
                      : 0}
                  </p>
                  <p className="text-center">{library.MARKET_CAP_SAFELUS}</p>
                </Grid>
                <Grid item xs={3} className="text-center">
                  <img src={reward02} style={{ height: 60 }} />
                  <p className="font-size-13 text-center w-100pc mt-10 text-bold">
                    {safeLusStatistic
                      ? formatLuS(safeLusStatistic.stakingCount)
                      : 0}
                  </p>
                  <p className="text-center">{library.HOLDERS}</p>
                </Grid>
                <Grid item xs={3} className="text-center">
                  <img src={reward03} style={{ height: 60 }} />
                  <p className="font-size-13 text-center w-100pc mt-10 text-bold">
                    {safeLusStatistic
                      ? formatLuS(safeLusStatistic.totalStaked)
                      : 0}{" "}
                    {LUS_TOKEN}
                  </p>
                  <p className="text-center">{library.TOTAL_STAKED}</p>
                </Grid>
                <Grid item xs={3} className="text-center">
                  <img src={reward04} style={{ height: 60 }} />
                  <p className="font-size-13 text-center w-100pc mt-10 text-bold">
                    {safeLusStatistic
                      ? formatLuS(safeLusStatistic.totalVolumePool)
                      : 0}{" "}
                    {LUS_TOKEN}
                  </p>
                  <p className="text-center nowrap">
                    {library.TOTAL_SHARE_POOLS}
                  </p>
                </Grid>
              </Grid>
              <div id="homepage-statistic">
                <Grid container>
                  <Grid item md={6} xs={12}>
                    <Typography>Recent staking</Typography>
                    <NewStaking />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography>Top holder</Typography>
                    <HomepageTopHolder />
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </Container>
        <Container maxWidth={false}>
          <div className="services">
            <Container>
              <div className="d-flex-cbt" style={{ padding: "30px 6rem" }}>
                <img src={service01} style={{ width: "50%", height: "50px" }} />
                <img src={service02} style={{ width: "50%", height: "40px" }} />
                <img src={service03} style={{ width: "50%", height: "45px" }} />
                <img src={service04} style={{ width: "50%", height: "44px" }} />
              </div>
            </Container>
          </div>
        </Container>
        <Container maxWidth={false}>
          <Container>
            <Grid
              container
              className="total-supply"
              style={{ padding: "6rem 6rem" }}
              justify="center"
              alignItems="center"
            >
              <Grid
                xs={12}
                className="mb-20"
                style={{
                  fontSize: "1.2em",
                }}
                item
              >
                <p>{library.FLOW_SAFE_LUS}</p>
                <p>{library.FLOW_SAFE_LUS_2}</p>
              </Grid>
              <Grid item xs={12} md={6}>
                <p className="decs">
                  <label>{library.POOL_HOLDER} (5%):</label>
                  <span>{library.POOL_HOLDER_SUB}</span>
                </p>
                <p className="decs">
                  <label>{library.POOL_REWARD} (1%):</label>
                  <span>{library.POOL_REWARD_SUB}</span>
                </p>
                <p className="decs">
                  <label>{library.POOL_BRAND_AMBASSADOR} (1%):</label>
                  <span>{library.POOL_BRAND_AMBASSADOR_SUB}</span>
                </p>
                <p className="decs">
                  <label>{library.AFFILIATE} (3%):</label>
                  <span>{library.AFFILIATE_SUB}</span>
                </p>
              </Grid>
              <Grid item xs={12} md={6} className="supply-chart">
                <img
                  src="/images/total-supply.png"
                  style={{
                    height: 350,
                    objectFit: "contain",
                  }}
                />
              </Grid>
            </Grid>
          </Container>
        </Container>
        <Brands />
        <Roadmap />
        <Footer />
      </div>
    </>
  );
}

export default Homepage;
