import React, { useState } from "react";
import Transition from "react-motion-ui-pack";
import { LUS_TOKEN } from "../../constants";
import { useSelector } from "react-redux";
import { spring } from "react-motion";
import { Container } from "@material-ui/core";
import Carousel from "react-multi-carousel";
import "./vertical-slide-animate.css";
import { formatLuS } from "../../settings/format";

function HomepageTopHolder() {
  const { safeLus } = useSelector((state) => state);
  const { topHolder } = safeLus;

  return topHolder ? (
    <div className="wrapper">
      <div className="carousel">
        {topHolder.items.map((item, index) => (
          <div className="carousel__item">
            <div
              className="carousel__item-head"
              style={{ backgroundColor: "#ff8dc6" }}
            >
              <small style={{ fontSize: 14 }}>#</small>
              {index + 1}
            </div>
            <div className="carousel__item-body">
              <p className="title">
                {item.displayName ? item.displayName : "Unknown"}
              </p>
              <p>
                {formatLuS(item.staked)} {LUS_TOKEN}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  ) : null;
}

export default HomepageTopHolder;
