export const GET_PROFILE = "GET_PROFILE";
export const LOGIN = "LOGIN";
export const SHOW_CREATE_STAKE_FROM = "SHOW_CREATE_STAKE_FROM";
export const GET_LUS_BALANCE = "GET_LUS_BALANCE";
export const GET_STAKE_HISTORY = "GET_STAKE_HISTORY";
export const GET_POOL_LIST = "GET_POOL_LIST";
export const GET_STAKING_V1 = "GET_STAKING_V1";
export const GET_TRANSACTION = "GET_TRANSACTION";
export const GET_MY_OPEN_SAFE_HISTORY = "GET_MY_OPEN_SAFE_HISTORY";
export const GET_TOP_HOLDER = "GET_TOP_HOLDER";
export const GET_USER_STATISTIC = "GET_USER_STATISTIC";
export const GET_USER_PROFIT_STATISTIC = "GET_USER_PROFIT_STATISTIC";
export const GET_SAFE_LUS_STATISTIC = "GET_SAFE_LUS_STATISTIC";
export const GET_F1_AFFILIATE = "GET_F1_AFFILIATE";
export const GET_LUS_PRICE = "GET_LUS_PRICE";
export const GET_MY_STAKED = "GET_MY_STAKED";
export const GET_MY_UNSAFE_HISTORY = "GET_MY_UNSAFE_HISTORY";
export const GET_SETTING = "GET_SETTING";
export const GET_TOP_UP_SETTING = "GET_TOP_UP_SETTING";

export const HOLDER = "HOLDER";
export const REWARD = "REWARD";
export const BA_REWARD = "BA_REWARD";
export const IN_RELEASE = "IN_RELEASE";
export const OPEN = "OPEN";
export const AGENCY = "AGENCY";

export const RANK_LEVEL = {
  NO_RANK: "No rank",
  BASIC: "No level",
  PARTNER: "Partner",
  MEMBER: "Member",
  AGENCY: "Agency",
  SILVER: "Silver",
  GOLD: "Gold",
  DIAMOND: "Diamond",
  MASTER: "Master",
  VIP1: "VIP 1",
  VIP2: "VIP 2",
};
export const ScreenSize = {
  Mobile: {
    value: "Mobile",
    maxWidth: 600,
    class: "mobile-layout",
  },
  Tablet: {
    value: "Tablet",
    maxWidth: 800,
    class: "tablet-layout",
  },
  Desktop: {
    value: "Desktop",
    maxWidth: 5678,
    class: "",
  },
};

//coin or token
export const LUS_PRICE = 0.04;
export const LUS_TOKEN = "LuS";
export const LUS_M_TOKEN = "LusM";

export const SafeLusTransactionType = {
  HOLDER_COMMISSION: 0,
  REWARD: 1,
  BA_REWARD: 2,
  AFFILIATE_COMMISSION: 3,
  RELEASE: 4,
  DAILY_RELEASE: 5,
};

export const supportCenterMenu = [
  {
    label: "Announcements",
    url: "https://announcements.safelus.finance/",
    isOutSite: true,
    key: "announcements",
  },
  {
    label: "Q&A",
    url: "https://support.safelus.finance/",
    isOutSite: true,
    key: "qa",
  },
  {
    label: "Telegram",
    url: "https://t.me/SAFELuS_bot",
    isOutSite: true,
    key: "telegram",
  },
];
