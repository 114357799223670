import {
  Button,
  MenuItem,
  IconButton,
  SwipeableDrawer,
  Collapse,
  Divider,
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  ExpandMore,
  ExpandLess,
  ArrowDropDown,
} from "@material-ui/icons";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { API_GET_MARKET_LUS } from "../../settings";
import { ScreenSize, supportCenterMenu } from "../../constants";
import "./style.scss";

function Menu() {
  const location = useLocation();
  const { setting } = useSelector((state) => state);
  const { viewMode } = setting;
  const [anchor] = useState(null);

  const menus = [
    {
      key: "home",
      label: "Home",
      url: "/home",
    },
    {
      key: "shopping",
      label: "Shopping",
      url: "https://lus.shopping/",
      isOutSite: true,
    },
    {
      key: "markets",
      label: "Markets",
      url: null,
      items: [
        {
          key: "spexchange",
          label: "SPExchange",
          url: `https://spexchange.io/page/trading/LuSUSDT`,
          isOutSite: true,
        },
        {
          key: "bsc-scan",
          label: "Pancakeswap",
          url: "https://pancakeswap.finance",
          isOutSite: true,
        },
      ],
    },
    // {
    //   key: "whitepaper",
    //   label: "Whitepaper",
    //   url: "https://drive.google.com/u/1/uc?id=18_9lQZIbuYJ14Hntii6DyIjfN_Omm_IH&export=download",
    //   isOutSite: true,
    // },
    {
      key: "support",
      label: "Support center",
      // url: "https://support.safelus.finance/",
      isOutSite: true,
      showMobile: true,
      items: supportCenterMenu,
    },
  ];

  return (
    <>
      {viewMode === ScreenSize.Mobile.value ||
      viewMode === ScreenSize.Tablet.value ? (
        <RenderMobile menus={menus} location={location} anchor={anchor} />
      ) : null}
      {viewMode !== ScreenSize.Mobile.value &&
      viewMode !== ScreenSize.Tablet.value ? (
        <RenderDesktop menus={menus} location={location} />
      ) : null}
    </>
  );
}

export default Menu;

function RenderDesktop({ menus, location, handleClickMenu }) {
  const anchorRef = useRef(null);
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [anchor, setAnchor] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  function handleClickMenu(e, item) {
    if (item.url) {
      if (item.isSPwallet) {
        window.location.replace(item.url);
      }
      if (item.isOutSite) {
        window.open(item.url);
      } else {
        history.push(item.url);
      }
    }
  }

  return (
    <div className="main-menu d-flex-cs ml-20">
      {menus.map(
        (item, index) =>
          !item.showMobile && (
            <div className="menu" key={index}>
              <Button
                className={"circular "}
                onClick={(e) => handleClickMenu(e, item)}
                endIcon={
                  item.items && item.items.length > 0 ? (
                    <ArrowDropDown className={"opacity-06"} />
                  ) : (
                    ""
                  )
                }
              >
                {item.label}
              </Button>
              {item.items ? (
                <ul className="submenu">
                  {item.items.map((menu, subIndex) => (
                    <li
                      key={subIndex}
                      onClick={(e) => handleClickMenu(e, menu)}
                    >
                      {menu.label}
                    </li>
                  ))}
                </ul>
              ) : (
                ""
              )}
            </div>
          )
      )}
    </div>
  );
}

function RenderMobile({ menus, location, handleClickMenu }) {
  const history = useHistory();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [activeCollapse, setActiveCollapse] = useState(null);

  function handleOpenDrawer() {
    setOpenDrawer(true);
  }
  function handleCloseDrawer() {
    setOpenDrawer(false);
  }

  function handleCollapse(key) {
    setActiveCollapse(key);
  }

  const _handleClickMenu = (e, item) => {
    if (item.url) {
      if (item.isSPwallet) {
        window.location.replace(item.url);
      }
      if (item.isOutSite) {
        window.open(item.url);
      } else {
        history.push(item.url);
      }
      handleCloseDrawer();
    } else {
      if (activeCollapse !== item.key) handleCollapse(item.key);
      else handleCollapse(null);
    }
  };

  return (
    <div className="main-menu d-flex-ce ml-20 w-100pc">
      <IconButton onClick={() => handleOpenDrawer()}>
        <MenuIcon style={{ fontSize: "2.5rem" }} />
      </IconButton>
      <SwipeableDrawer
        className="main-drawer"
        anchor={"right"}
        open={openDrawer}
        onOpen={() => handleOpenDrawer()}
        onClose={() => handleCloseDrawer()}
      >
        {menus &&
          menus.map((item) => (
            <div key={item.key}>
              <Button
                className={
                  location.pathname.indexOf(item.url) >= 0 ? "bg-light" : ""
                }
                onClick={(e) => _handleClickMenu(e, item)}
              >
                {item.label}
                {item.items && item.items.length > 0 && (
                  <>
                    {activeCollapse === item.key ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </>
                )}
              </Button>
              {item.items && item.items.length > 0 && (
                <Collapse
                  in={activeCollapse === item.key}
                  timeout="auto"
                  unmountOnExit
                >
                  {item.items.map((menu) => (
                    <MenuItem
                      onClick={(e) => handleClickMenu(e, menu)}
                      key={menu.key}
                    >
                      {menu.label}
                    </MenuItem>
                  ))}
                </Collapse>
              )}
              <Divider />
            </div>
          ))}
      </SwipeableDrawer>
    </div>
  );
}

// function HideOnScroll(props) {
//   const { children, window } = props;
//   const trigger = useScrollTrigger({ target: window ? window() : undefined });

//   return (
//     <Slide appear={false} direction="down" in={!trigger}>
//       {children}
//     </Slide>
//   );
// }
