/* eslint-disable jsx-a11y/alt-text */
import { Avatar, Container, Grid, Link, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserMenu from "../components/UserMenu";
import { _getUserStatistic } from "../actions/safeLusActions";
import { formatLuS, formatUSD } from "../settings/format";
import { LUS_TOKEN, RANK_LEVEL } from "../constants";
import MyPoolHistory from "../components/my-safe/MyPoolHolderHistory";
import Members from "../components/affiliate/Members";
import CustomTooltip from "../components/CustomTooltip";
import RevenueList from "../components/RevenueList";

function Affiliate() {
  const { safeLus, setting } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { myStatistic, lusPrice } = safeLus;
  const { library } = setting;
  const [currentTab, setCurrentTab] = useState(0);
  const [showRevenueList, setShowRevenueList] = useState(false);

  const menus = [
    {
      title: library.MEMBERS + " F1",
      index: 0,
      component: <Members />,
    },
    {
      title: library.AFFILIATE_COMMISSION,
      index: 1,
      component: (
        <MyPoolHistory type="AFFILIATE_COMMISSION" showDisplayName={true} />
      ),
    },
    {
      title: "Ranking bonus",
      index: 2,
      component: <MyPoolHistory type="RANKING_BONUS" showDisplayName={true} />,
    },
  ];

  useEffect(() => {
    dispatch(_getUserStatistic());
  }, [dispatch]);

  return (
    <>
      {myStatistic && (
        <div>
          <UserMenu />
          <Container>
            <div className="bg-content">
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Grid
                    container
                    className="gray-bg pt-20 pb-20 br-5 text-center mobile-br-0"
                  >
                    <Grid
                      item
                      xs={6}
                      style={{
                        borderRight: "1px solid rgba(255, 255, 255, 0.12)",
                        padding: "0 10px",
                      }}
                    >
                      <Grid
                        container
                        justify="space-between"
                        alignItems="center"
                      >
                        <Grid item>
                          <Typography className="opacity-06">
                            {library.MY_LEVEL}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Typography>
                              {RANK_LEVEL[myStatistic.level]}
                            </Typography>
                            <Avatar
                              src={
                                `/images/level-` +
                                (myStatistic.level === "AGENCY"
                                  ? `partner`
                                  : `member`) +
                                `.png`
                              }
                              style={{
                                height: 25,
                                width: 25,
                                objectFit: "contain",
                                marginLeft: 10,
                              }}
                              className="hide-mobile"
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        justify="space-between"
                        className="mt-5 mb-5"
                        alignItems="center"
                      >
                        <Grid item>
                          <Typography className="opacity-06">
                            {library.MY_RANK}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Typography>
                              {RANK_LEVEL[myStatistic.rank]}
                            </Typography>
                            {myStatistic.rank !== "NO_RANK" && (
                              <Avatar
                                src={
                                  `/images/rank-` +
                                  myStatistic.rank.toLowerCase() +
                                  `.png`
                                }
                                style={{
                                  height: 25,
                                  width: 25,
                                  objectFit: "contain",
                                  marginLeft: 10,
                                }}
                                className="hide-mobile"
                              />
                            )}
                          </div>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        justify="space-between"
                        className="mt-5 mb-5"
                      >
                        <Grid item style={{ display: "flex" }}>
                          <Typography className="opacity-06">
                            Affiliate
                          </Typography>
                          <CustomTooltip
                            className="affiliate"
                            content={
                              <div className="text-center font-size-08">
                                <div className="mb-10">
                                  {library.AFFILIATE_DES}
                                </div>
                                <div>{library.POOL_HOLDER_CONDITION}</div>
                              </div>
                            }
                          />
                        </Grid>
                        <Grid item>
                          <Typography className="text-bold">
                            {myStatistic && myStatistic.totalF1Affiliate}/10{" "}
                            <span style={{ fontWeight: 300 }}>
                              {library.AGENCY}
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container justify="space-between">
                        <Grid item style={{ display: "flex" }}>
                          <Typography className="opacity-06">
                            Reserve{" "}
                          </Typography>
                          <CustomTooltip
                            className="affiliate"
                            content="Reserve revenue"
                          />
                        </Grid>
                        <Grid item>
                          <Typography className="text-bold">
                            {formatLuS(myStatistic.v1Staked)}
                            <small>$</small>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6} style={{ position: "relative" }}>
                      <Typography className="mb-20 opacity-06">
                        {library.TOTAL_REVENUE}{" "}
                      </Typography>
                      <Typography variant="h5">
                        {formatUSD(myStatistic.totalAffiliateUsdRevenue)}
                        <small>$</small>
                      </Typography>
                      <Typography variant="body1">
                        {formatLuS(myStatistic.totalAffiliateRevenue)}{" "}
                        {LUS_TOKEN}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid
                    container
                    className="gray-bg pt-20 br-5 text-center mobile-br-0"
                    style={{
                      paddingBottom: 33,
                    }}
                  >
                    <Grid
                      item
                      xs={6}
                      style={{
                        borderRight: "1px solid rgba(255, 255, 255, 0.12)",
                      }}
                    >
                      <Typography className="mb-20 opacity-06">
                        {library.TOTAL_COMMISSION}
                      </Typography>
                      <Typography variant="h5">
                        {formatLuS(myStatistic.affiliateCommissionEarned)}{" "}
                        {LUS_TOKEN}
                      </Typography>
                      <Typography variant="body1">
                        &#8776;{" "}
                        {formatUSD(
                          myStatistic.affiliateCommissionEarned * lusPrice
                        )}
                        <small>$</small>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className="mb-20 opacity-06">
                        {library.TOTAL_RANKING_BONUS}
                      </Typography>
                      <Typography variant="h5">
                        {formatLuS(myStatistic.rankingBonusEarned)} {LUS_TOKEN}
                      </Typography>
                      <Typography variant="body1">
                        &#8776;{" "}
                        {formatUSD(myStatistic.rankingBonusEarned * lusPrice)}
                        <small>$</small>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <div className="mt-30 mb-10 dashboard-option">
                {menus.map((item, index) => (
                  <div
                    key={index}
                    className={currentTab === item.index ? "active" : ""}
                    onClick={() => setCurrentTab(item.index)}
                  >
                    {item.title}
                  </div>
                ))}
              </div>
              {menus[currentTab].component}
            </div>
          </Container>
        </div>
      )}
      <RevenueList
        showRevenueList={showRevenueList}
        _close={() => setShowRevenueList(false)}
      />
    </>
  );
}

export default Affiliate;
