import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useDispatch, useSelector } from "react-redux";
import { LUS_TOKEN } from "../constants";
import { formatLuS } from "../settings/format";
import { _getTopHolder } from "../actions/safeLusActions";
import { CircularProgress, TablePagination } from "@material-ui/core";

export default function TopHolder() {
  const { safeLus, setting } = useSelector((state) => state);
  const { topHolder } = safeLus;
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { library } = setting;

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value));
    setPage(1);
  };

  useEffect(() => {
    dispatch(_getTopHolder(page, pageSize));
  }, [dispatch, page, pageSize]);

  return (
    <div
      className="gray-bg pd-20 br-5 mobile-br-0 mobile-p-0"
      style={{ color: "#fff" }}
    >
      <TableContainer>
        <Table size="small" aria-label="a dense table">
          <TableHead className="white-color hide-mobile opacity-08">
            <TableRow>
              <TableCell>{library.TOP}</TableCell>
              <TableCell>{library.ACCOUNT}</TableCell>
              <TableCell>
                {library.SAFE} ({LUS_TOKEN})
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {topHolder ? (
              topHolder.items.length > 0 ? (
                topHolder.items.map((row, index) => (
                  <React.Fragment key={index}>
                    <TableRow className="hide-mobile text-bold">
                      <TableCell
                        component="th"
                        scope="row"
                        className="text-bold"
                      >
                        #{pageSize * (page - 1) + index + 1}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="text-bold"
                      >
                        {row.displayName}
                      </TableCell>
                      <TableCell className="text-bold">
                        {formatLuS(row.staked)}
                      </TableCell>
                    </TableRow>
                    <TableRow className="show-mobile show-mobile-table-row">
                      <TableCell colSpan={3} className="text-center">
                        <div className="d-flex-sbt">
                          <div className="text-bold">
                            #{index + 1} {row.displayName}
                          </div>
                          <div className="text-bold">
                            {formatLuS(row.staked)} {LUS_TOKEN}
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3} className="text-center">
                    {library.NO_RECORDS_FOUND}
                  </TableCell>
                </TableRow>
              )
            ) : (
              <TableRow>
                <TableCell colSpan={3}>
                  <div style={{ textAlign: "center" }}>
                    <CircularProgress style={{ margin: "auto" }} />
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {topHolder && (
          <TablePagination
            component="div"
            count={topHolder.itemCount}
            page={page - 1}
            onChangePage={handleChangePage}
            rowsPerPage={pageSize}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </TableContainer>
    </div>
  );
}
