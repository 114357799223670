import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import HighlightOffSharpIcon from "@material-ui/icons/HighlightOffSharp";
import { useDispatch, useSelector } from "react-redux";
import {
  AGENCY,
  LUS_M_TOKEN,
  LUS_TOKEN,
  SHOW_CREATE_STAKE_FROM,
} from "../constants";
import background from "../images/rocket-bg-1.jpg";
import {
  deleteText,
  formatInputLuS,
  formatLuS,
  formatUSD,
  formatLusFixDecimal,
} from "../settings/format";
import { post } from "../utils/api";
import { API_CREATE_STAKE } from "../settings/apiEndpoint";
import { CustomToast } from "../actions";
import {
  _getBalance,
  _getMyOpenStake,
  _getMyTotalStakingAmount,
  _getPoolList,
  _getStakeHistory,
  _getStakingV1,
} from "../actions/safeLusActions";
import { Loader } from "semantic-ui-react";
import logo from "../images/logo.png";
import CachedIcon from "@material-ui/icons/Cached";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { isBeta, isProduction, SPWALLET_URL } from "../settings";
import { Warning } from "@material-ui/icons";
import Upgrade from "./Upgrade";

import icon from "../images/save-money-icon.png";

const minimumUSD = 100;

function CreateStake({ _back, _setStakeType, stakeType }) {
  const { setting, safeLus } = useSelector((state) => state);
  const dispatch = useDispatch();
  const {
    lusBalance,
    lusMBalance,
    lusPrice,
    safeLusSetting,
    stakingV1,
    mySafeHistory,
  } = safeLus;
  const [coin, setCoin] = useState(LUS_TOKEN);
  const [amount, setAmount] = useState(0);
  const [error, setError] = useState(null);
  const [isFlag, setIsFlag] = useState(true);
  const { library } = setting;
  const [isLoading, setIsLoading] = useState(false);
  const minimumAmount = parseInt(
    minimumUSD / lusPrice + ((minimumUSD / lusPrice) * 2) / 100
  );
  const [isConforming, setIsConforming] = useState(false);
  const [policyError, setPolicyError] = useState(null);
  const [estimateMaximum, setEstimateMaximum] = useState(0);

  const checkAmount = () => {
    const balance = coin === LUS_TOKEN ? lusBalance : lusMBalance;
    const tempAmount = parseFloat(deleteText(amount));
    setError(null);
    if (balance > 0) {
      if (coin === LUS_TOKEN) {
        if (tempAmount > parseInt(balance)) {
          setError(library.BALANCE_IS_NOT_AVAILABLE);
          return false;
        } else {
          if (tempAmount < minimumAmount) {
            if (balance < minimumAmount) {
              setError(
                library.PLEASE_ENTER_AMOUNT +
                  " > " +
                  formatLuS(minimumAmount) +
                  " " +
                  coin
              );
            } else {
              setError(
                library.PLEASE_ENTER_AMOUNT +
                  " " +
                  formatLuS(minimumAmount) +
                  " - " +
                  formatLuS(parseInt(balance)) +
                  " " +
                  coin
              );
            }
            return false;
          }
        }
      } else {
        if (tempAmount > balance) {
          setError(library.BALANCE_IS_NOT_AVAILABLE);
          return false;
        } else {
          if (tempAmount < minimumAmount) {
            if (balance < minimumAmount) {
              setError(
                library.PLEASE_ENTER_AMOUNT +
                  " > " +
                  formatLuS(minimumAmount) +
                  " " +
                  coin
              );
            } else {
              setError(
                library.PLEASE_ENTER_AMOUNT +
                  " " +
                  formatLuS(minimumAmount) +
                  " - " +
                  formatLuS(parseInt(balance)) +
                  " " +
                  coin
              );
            }
            return false;
          }
        }
      }
    } else {
      setError(library.BALANCE_IS_NOT_AVAILABLE);
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (!isFlag) {
      checkAmount();
    }
    return () => setError(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount]);

  useEffect(() => setIsFlag(false), []);

  const _handleChangeAmount = (e) => {
    if (coin === LUS_TOKEN) setAmount(formatInputLuS(e.target.value));
  };

  const _handleSafe = (e) => {
    e.preventDefault();
    const check = checkAmount();
    setPolicyError(null);
    if (check) {
      setIsLoading(true);
      if (isConforming) {
        post(
          API_CREATE_STAKE,
          {
            amount: parseFloat(deleteText(amount)),
            coin,
          },
          (data) => {
            CustomToast(
              "success",
              library.SAFE_LUS_SUCCESS +
                " " +
                formatLuS(data.initAmount) +
                " " +
                LUS_TOKEN
            );
            dispatch(_getStakeHistory(1, 10));
            dispatch(_getPoolList());
            dispatch(_getBalance());
            dispatch(_getMyOpenStake(1, 10));
            dispatch(_getMyTotalStakingAmount());
            dispatch({
              type: SHOW_CREATE_STAKE_FROM,
              payload: false,
            });
            setIsLoading(false);
          },
          (error) => {
            CustomToast("error", error.code);
            setIsLoading(false);
          }
        );
      } else {
        post(
          `/lus-staking-service/v2/staking/maximum-assumption-earning`,
          {
            amount: parseFloat(deleteText(amount)),
            coin,
          },
          (data) => {
            setEstimateMaximum(data.maximumEarning);
            setIsLoading(false);
            setIsConforming(true);
          }
        );
      }
    }
  };

  const lusAmountToAgency = safeLusSetting
    ? safeLusSetting.filter((item) => item.memberLevel === AGENCY)[0]
        .minAmountDisplay / lusPrice
    : 0;
  const myStaked = mySafeHistory ? mySafeHistory.meta.totalStaked : 0;
  const currentStake = parseFloat(deleteText(amount)) + myStaked;
  const currentPercentToAgency = (currentStake / lusAmountToAgency) * 100;

  const myUSDTotalStakingAmount = mySafeHistory
    ? mySafeHistory.meta.totalUsdStaked
    : 0;
  const uptoAgency =
    safeLusSetting &&
    safeLusSetting.filter((item) => item.memberLevel === AGENCY)[0]
      .minAmountDisplay;

  const _handleSetCoin = (e) => {
    const { value } = e.target;
    setCoin(value);
  };

  useEffect(() => {
    if (coin === "LusM") {
      setAmount(lusMBalance);
    } else {
      setAmount(0);
    }
  }, [coin, lusMBalance]);

  return (
    <>
      {/* {isConforming && (
        <Typography variant="h6" className="mb-10">
          {library.CONFIRMING_FOR_STAKE}
        </Typography>
      )} */}
      {!isConforming && (
        <a
          className="swap-banner"
          href={`${SPWALLET_URL}/swap`}
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/banner.001.png" alt="" />
          <div className="text">
            Click here to BUY LuS <ArrowRightIcon />
          </div>
        </a>
      )}
      {!isConforming && (
        <FormControl
          component="fieldset"
          fullWidth
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <RadioGroup
            aria-label="gender"
            name="gender1"
            row={true}
            value={stakeType}
            onChange={(e) => _setStakeType(e.target.value)}
          >
            <FormControlLabel
              value="create"
              control={<Radio />}
              label={library.STAKE_NEW}
            />
            {stakingV1 && stakingV1.length > 0 && (
              <FormControlLabel
                value="transfer"
                control={<Radio />}
                label={library.TRANSFER}
              />
            )}
          </RadioGroup>
          <div
            style={{
              marginLeft: 20,
              backgroundColor: "rgb(94, 102, 115)",
              borderRadius: 4,
              padding: 3,
              cursor: "pointer",
            }}
          >
            <CachedIcon
              style={{
                lineHeight: "0px",
                display: "block",
              }}
              onClick={() => {
                dispatch(_getStakingV1());
                dispatch(_getBalance());
              }}
            />
          </div>
        </FormControl>
      )}
      <form onSubmit={_handleSafe}>
        <div className={isLoading ? "loading active" : "loading"}>
          <CircularProgress color="inherit" />
        </div>
        {isConforming ? (
          <div>
            <Typography align="center" className="mt-30">
              <img
                src={icon}
                style={{
                  width: 150,
                  padding: 10,
                }}
              />
            </Typography>
            <Typography align="center">
              <span className="font-size-12">
                <span className="opacity-06">{library.STAKE_CONFIRM_MES}</span>{" "}
                <span>
                  {formatLuS(amount)} {LUS_TOKEN}?
                </span>
              </span>
            </Typography>
            <Typography align="center" className="mt-50">
              <span className="opacity-06">{library.MAXIMUM_EARNING}</span>
              <span className=" d-block font-size-17">
                {formatLuS(estimateMaximum)} {LUS_TOKEN}
              </span>
            </Typography>
            {/* <Typography className="d-flex-cbt">
              <span className="opacity-06">{library.AMOUNT}</span>{" "}
              <span>
                {formatLuS(deleteText(amount))} {LUS_TOKEN}
              </span>
            </Typography>
            <Divider className="mt-10 mb-10" />
            <Typography className="d-flex-cbt">
              <span className="opacity-06">
                {library.DONATE_TO_POOLS_HOLDER} (5%)
              </span>
              <span>
                {formatLuS((deleteText(amount) * 5) / 100)} {LUS_TOKEN}
              </span>
            </Typography>
            <Typography className="d-flex-cbt">
              <span className="opacity-06">
                {library.DONATE_TO_POOLS_REWARD} (1%)
              </span>
              <span>
                {formatLuS((deleteText(amount) * 1) / 100)} {LUS_TOKEN}{" "}
              </span>
            </Typography>
            <Typography className="d-flex-cbt">
              <span className="opacity-06">
                {library.DONATE_TO_POOLS_PROMOTION} (1%)
              </span>
              <span>
                {formatLuS((deleteText(amount) * 1) / 100)} {LUS_TOKEN}
              </span>
            </Typography>
            <Typography className="d-flex-cbt">
              <span className="opacity-06">
                {library.DONATE_TO_AFFILIATE} (3%)
              </span>
              <span>
                {formatLuS((deleteText(amount) * 3) / 100)} {LUS_TOKEN}
              </span>
            </Typography>
            <Divider className="mt-10 mb-10" />
            <Typography className="d-flex-cbt">
              <span className="opacity-06">{library.TOTAL_DONATE} (10%)</span>
              <span className="text-bold">
                -{formatLuS((deleteText(amount) * 10) / 100)} {LUS_TOKEN}
              </span>
            </Typography>
            <Typography className="d-flex-cbt">
              <span className="opacity-06">{library.TOTAL_STAKE}</span>
              <span className="text-bold">
                {formatLuS(
                  deleteText(amount) - (deleteText(amount) * 10) / 100
                )}{" "}
                {LUS_TOKEN}
              </span>
            </Typography>
            <Divider className="mt-10 mb-10" />
            <Typography className="d-flex-cbt">
              <span className="opacity-06">{library.MAXIMUM_EARNING}</span>
              <span className="text-bold">
                {formatLuS(estimateMaximum)} {LUS_TOKEN}
              </span>
            </Typography> */}
            <FormGroup
              component="fieldset"
              style={{
                position: "absolute",
                bottom: 30,
                right: 20,
                marginBottom: 0,
              }}
            >
              <Grid container>
                <Grid item>
                  <Button
                    type="button"
                    onClick={() => setIsConforming(false)}
                    className="btn-cancel"
                    style={{ width: 100, marginRight: 10, marginTop: 0 }}
                  >
                    {library.BACK}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    className="btn-submit"
                    style={{ width: 100, marginTop: 0 }}
                  >
                    {library.CONFIRM}
                  </Button>
                </Grid>
              </Grid>
            </FormGroup>
          </div>
        ) : (
          <div>
            <FormControl component="fieldset" hiddenLabel fullWidth>
              <InputLabel id="demo-simple-select-label">Select coin</InputLabel>
              <Select
                id="select-coin"
                fullWidth
                value={coin}
                onChange={_handleSetCoin}
              >
                <MenuItem value={LUS_TOKEN} className="w-100pc">
                  <div className="d-flex-ebt w-100pc">
                    <div>{LUS_TOKEN}</div>
                    <div>
                      {library.AVAILABLE}: {formatLuS(parseInt(lusBalance))}
                    </div>
                  </div>
                </MenuItem>
                <MenuItem value={LUS_M_TOKEN}>
                  <div className="d-flex-ebt w-100pc">
                    <div>{LUS_M_TOKEN}</div>
                    <div>
                      {library.AVAILABLE}: {formatLusFixDecimal(lusMBalance)}
                    </div>
                  </div>
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl component="fieldset" fullWidth>
              <TextField
                label="Amount"
                fullWidth
                value={amount}
                onChange={_handleChangeAmount}
                disabled={coin === "LusM"}
              />
            </FormControl>
            <div className="error-message">{error}</div>
            {myUSDTotalStakingAmount < uptoAgency && (
              <FormControl component="fieldset" fullWidth>
                <Grid container alignItems="flex-end">
                  <Grid item style={{ flex: 1 }}>
                    <LinearProgress
                      variant="determinate"
                      value={
                        currentPercentToAgency > 100
                          ? 100
                          : currentPercentToAgency
                      }
                    />
                    <Typography align="right">
                      {formatUSD(currentStake)} / {formatUSD(lusAmountToAgency)}{" "}
                      {LUS_TOKEN}
                    </Typography>
                  </Grid>
                  <Grid item style={{ marginLeft: "10px" }}>
                    <Avatar
                      sizes="large"
                      src="/images/level-partner.png"
                      style={{
                        float: "right",
                        filter:
                          currentPercentToAgency > 100
                            ? "unset"
                            : "grayscale(100%)",
                      }}
                    />
                  </Grid>
                </Grid>
                <Typography variant="caption" className="opacity-06">
                  Stake more than <small>$</small>
                  {formatLuS(uptoAgency)} (≈{formatLuS(lusAmountToAgency)}{" "}
                  {LUS_TOKEN}) to become an agency
                </Typography>
              </FormControl>
            )}
            <div className="error-message">{policyError}</div>
            <FormGroup
              component="fieldset"
              style={{ position: "absolute", bottom: 30, right: 20 }}
            >
              <Grid container justify="flex-end">
                <Grid item>
                  <Button
                    type="button"
                    onClick={_back}
                    className="btn-cancel"
                    style={{ width: 100, marginRight: 10, marginTop: 0 }}
                  >
                    {library.BACK}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    className="btn-submit"
                    style={{ width: 100, marginTop: 0 }}
                  >
                    {library.STAKE}
                  </Button>
                </Grid>
              </Grid>
            </FormGroup>
          </div>
        )}
      </form>
    </>
  );
}

function Transfer({ _back, stakeType, _setStakeType }) {
  const { setting, safeLus } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { stakingV1 } = safeLus;
  const [selectedStaking, setSelectedStaking] = useState(null);
  const [error, setError] = useState(null);
  const { library } = setting;
  const [isFlag, setIsFlag] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isConforming, setIsConforming] = useState(false);
  const [estimateMaximum, setEstimateMaximum] = useState(0);

  const checkStaking = () => {
    if (selectedStaking) {
      setError(null);
      return true;
    } else {
      setError(library.PLEASE_SELECT_STAKING);
      return false;
    }
  };

  useEffect(() => setIsFlag(false), []);
  useEffect(() => {
    if (!isFlag) {
      checkStaking();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStaking]);

  const _handleSafe = (e) => {
    const check = checkStaking();
    e.preventDefault();
    if (check) {
      setIsLoading(true);
      if (isConforming) {
        post(
          API_CREATE_STAKE,
          {
            stakingId: selectedStaking,
          },
          (data) => {
            CustomToast(
              "success",
              library.SAFE_LUS_SUCCESS +
                " " +
                formatLuS(data.initAmount) +
                " " +
                LUS_TOKEN
            );
            dispatch(_getStakeHistory(1, 10));
            dispatch(_getPoolList());
            dispatch(_getBalance());
            dispatch(_getStakingV1());
            dispatch({
              type: SHOW_CREATE_STAKE_FROM,
              payload: false,
            });
            setIsLoading(false);
          },
          (error) => {
            setIsLoading(false);
            CustomToast("error", error.code);
          }
        );
      } else {
        post(
          `/lus-staking-service/v2/staking/maximum-assumption-earning`,
          {
            amount: parseFloat(deleteText(amount)),
            coin: "LuS",
          },
          (data) => {
            setEstimateMaximum(data.maximumEarning);
            setIsLoading(false);
            setIsConforming(true);
          }
        );
      }
    }
  };

  let amount = 0;
  let price = 0;
  if (stakingV1) {
    if (selectedStaking) {
      amount = stakingV1.filter((item) => item.id === selectedStaking)[0]
        .amount;
      price = stakingV1.filter((item) => item.id === selectedStaking)[0].price;
    }
  }
  amount = amount * price;

  return stakingV1 ? (
    <>
      {isConforming && (
        <Typography variant="h6" className="mb-10">
          {library.CONFIRMING_FOR_STAKE}
        </Typography>
      )}
      {!isConforming && (
        <a
          className="swap-banner"
          href={`${SPWALLET_URL}/swap`}
          target="_blank"
        >
          <img src="/images/banner.001.png" />
          <div className="text">
            Click here to BUY LuS <ArrowRightIcon />
          </div>
        </a>
      )}
      {!isConforming && (
        <FormControl
          component="fieldset"
          fullWidth
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <RadioGroup
            aria-label="gender"
            name="gender1"
            row={true}
            value={stakeType}
            onChange={(e) => _setStakeType(e.target.value)}
          >
            <FormControlLabel
              value="create"
              control={<Radio />}
              label={library.STAKE_NEW}
            />
            {stakingV1 && stakingV1.length > 0 && (
              <FormControlLabel
                value="transfer"
                control={<Radio />}
                label={library.TRANSFER}
              />
            )}
          </RadioGroup>
          <div
            style={{
              marginLeft: 20,
              backgroundColor: "rgb(94, 102, 115)",
              borderRadius: 4,
              padding: 3,
              cursor: "pointer",
            }}
          >
            <CachedIcon
              style={{
                lineHeight: "0px",
                display: "block",
              }}
              onClick={() => {
                dispatch(_getStakingV1());
                dispatch(_getBalance());
              }}
            />
          </div>
        </FormControl>
      )}
      <form onSubmit={_handleSafe}>
        <div className={isLoading ? "loading active" : "loading"}>
          <CircularProgress color="inherit" />
        </div>
        {isConforming && selectedStaking && (
          <div>
            <Typography align="center" className="mt-30">
              <img
                src={icon}
                style={{
                  width: 150,
                  padding: 10,
                }}
              />
            </Typography>
            <Typography align="center">
              <span className="font-size-12">
                <span className="opacity-06">{library.STAKE_CONFIRM_MES}</span>{" "}
                <span>
                  {formatLuS(amount)} {LUS_TOKEN}?
                </span>
              </span>
            </Typography>
            <Typography align="center" className="mt-50">
              <span className="opacity-06">{library.MAXIMUM_EARNING}</span>
              <span className=" d-block font-size-17">
                {formatLuS(estimateMaximum)} {LUS_TOKEN}
              </span>
            </Typography>
            {/* <Typography className="d-flex-cbt">
              <span className="opacity-06">{library.AMOUNT}</span>{" "}
              <span>
                {formatLuS(amount)} {LUS_TOKEN}
              </span>
            </Typography>
            <Divider className="mt-10 mb-10" />
            <Typography className="d-flex-cbt">
              <span className="opacity-06">
                {library.DONATE_TO_POOLS_HOLDER} (5%)
              </span>
              <span>
                {formatLuS((amount * 5) / 100)} {LUS_TOKEN}
              </span>
            </Typography>
            <Typography className="d-flex-cbt">
              <span className="opacity-06">
                {library.DONATE_TO_POOLS_REWARD} (1%)
              </span>
              <span>
                {formatLuS((amount * 1) / 100)} {LUS_TOKEN}{" "}
              </span>
            </Typography>
            <Typography className="d-flex-cbt">
              <span className="opacity-06">
                {library.DONATE_TO_POOLS_PROMOTION} (1%)
              </span>
              <span>
                {formatLuS((amount * 1) / 100)} {LUS_TOKEN}
              </span>
            </Typography>
            <Typography className="d-flex-cbt">
              <span className="opacity-06">
                {library.DONATE_TO_AFFILIATE} (3%)
              </span>
              <span>
                {formatLuS((amount * 3) / 100)} {LUS_TOKEN}
              </span>
            </Typography>
            <Divider className="mt-10 mb-10" />
            <Typography className="d-flex-cbt">
              <span className="opacity-06">{library.TOTAL_DONATE} (10%)</span>
              <span className="text-bold">
                -{formatLuS((amount * 10) / 100)} {LUS_TOKEN}
              </span>
            </Typography>
            <Typography className="d-flex-cbt">
              <span className="opacity-06">{library.TOTAL_STAKE}</span>
              <span className="text-bold">
                {formatLuS(amount - (amount * 10) / 100)} {LUS_TOKEN}
              </span>
            </Typography>
            <Divider className="mt-10 mb-10" />
            <Typography className="d-flex-cbt">
              <span className="opacity-06">{library.MAXIMUM_EARNING}</span>
              <span className="text-bold">
                {formatLuS(estimateMaximum)} {LUS_TOKEN}
              </span>
            </Typography> */}
            <FormGroup
              component="fieldset"
              style={{
                position: "absolute",
                bottom: 30,
                right: 20,
                marginBottom: 0,
              }}
            >
              <Grid container>
                <Grid item>
                  <Button
                    type="button"
                    onClick={() => setIsConforming(false)}
                    className="btn-cancel"
                    style={{ width: 100, marginRight: 10, marginTop: 0 }}
                  >
                    {library.BACK}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    className="btn-submit"
                    style={{ width: 100, marginTop: 0 }}
                  >
                    {library.CONFIRM}
                  </Button>
                </Grid>
              </Grid>
            </FormGroup>
          </div>
        )}
        {!isConforming && (
          <div>
            <FormControl component="fieldset" hiddenLabel fullWidth>
              <InputLabel id="demo-simple-select-label">
                {library.SELECT_STAKING}
              </InputLabel>
              <Select
                id="select-staking"
                fullWidth
                value={selectedStaking}
                onChange={(e) => setSelectedStaking(e.target.value)}
              >
                {stakingV1.map((item, index) => (
                  <MenuItem value={item.id} className="w-100pc">
                    <div className="d-flex-ebt w-100pc">
                      <div>ID: #{item.id}</div>
                      <div>
                        {formatLuS(item.amount * item.price)} {item.quote}
                      </div>
                    </div>
                  </MenuItem>
                ))}
              </Select>
              <div className="error-message">{error}</div>
            </FormControl>
            <FormControl
              fullWidth
              component="fieldset"
              style={{
                position: "absolute",
                bottom: 30,
                right: 20,
                marginBottom: 0,
              }}
            >
              <Grid container justify="flex-end">
                <Grid item>
                  <Button
                    type="button"
                    onClick={_back}
                    className="btn-cancel"
                    style={{ width: 100, marginRight: 10, marginTop: 0 }}
                  >
                    {library.BACK}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    className="btn-submit"
                    style={{ width: 100, marginTop: 0 }}
                  >
                    {library.TRANSFER}
                  </Button>
                </Grid>
              </Grid>
            </FormControl>
          </div>
        )}
      </form>
    </>
  ) : (
    <div
      className="w-100pc"
      style={{
        height: 230,
      }}
    >
      <Loader active />
    </div>
  );
}

function Policy({ _next }) {
  const { setting } = useSelector((state) => state);
  const { library } = setting;
  const dispatch = useDispatch();
  const [check1, setCheck1] = useState(false);
  const [check2, setCheck2] = useState(false);
  const [policyError, setPolicyError] = useState(null);

  const _handlePolicy = () => {
    setPolicyError(null);
    if (!check1 || !check2) {
      setPolicyError(
        library.PLEASER_CHECK_TOS + " and Cautions when using SafeLuS service."
      );
    } else {
      _next();
    }
  };

  return (
    <>
      <Typography variant="h5" align="center">
        Cautions when using SafeLuS service
      </Typography>
      <div
        style={{
          height: 200,
          overflow: "auto",
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        {/* <img src={policy} className="w-100pc" /> */}
        <br />
        <Typography variant="inherit">
          Please read carefully the following notes regarding SafeLuS's services
          before registering and using the services. <br />
          <br />
          1.The content provided in the service is only necessary in the process
          of using the service, not for the purpose of advising or encouraging
          transactions or investment. All digital prod- uct content as well as
          information provided by the service business may contain errors,
          delays or inaccuracies, so We and the service business will not be
          responsible for any information period.
          <br />
          <br />
          2. The ownership of LuS tokens is not sponsored by any responsibility
          or interest to the owner or the company. When comparing the assets of
          different exchanges, the value of the LuS token does not change, so
          buyers should consider the announcement of course'. After the LuS
          token is listed, the volatility of the value of the LuS token will be
          enormous, so buyers should understand that the price of the LuS token
          may increase or decrease at that point. <br />
          <br />
          3. SafeLuS is not a stock exchange or crypto exchange, product or
          service portfolio, business unit, or unit of institutional investment
          plans on par with any other institution. <br />
          <br />
          4. If you want to exchange LuS or another token, or exchange one token
          for another, all tokens will not be understood, interpreted,
          classified or processed as follows. (a) currency other than
          cryptocurrency; (b) liabilities, shares or shares issued by a legal
          entity; (c) rights, options and derivatives to such liabilities or
          shares; (d) A contractual right under a difference or other contract
          for the purpose of securing a profit or pretending to avoid a loss; Or
          e) an entity in a collective investment program, business trust, or
          other type of securities. <br />
          <br />
          5. The token used for SafeLuS is cryptocurrency, and cryptocurrency is
          traded around the world 24 hours a day, 365 days a year, and rapid
          fluctuations in market prices can lead to continuous value
          fluctuations. <br />
          <br />
          6. LuS tokens or other tokens have no value or be used for exchange,
          purchase, sale, mortgage, collateral, loans, etc. for real goods in
          the real economic market. <br />
          <br />
          7. As SafeLuS is an intermediary service, customers do not need to
          provide personal information or contact us through email, phone, chat
          application, message, etc. under the name SafeLuS under any
          circumstances. <br />
          <br />
          8. Any investment arising from the service as well as information
          provided in the service can be subject to losses, the member will be
          the one who considers the investment and is fully responsible for his
          or her investment. We will not be responsible for any investment loss
          of SafeLuS users. <br />
          <br />
          9.Please refer to the ‘Disclaimer' in the introductory file for the
          SafeLuS and Applications section for specific information. <br />
          <br />
          Thank you ! <br />
          <br />
          SafeLuS team
        </Typography>
      </div>
      <FormControlLabel
        control={
          <Checkbox
            checked={check1}
            onChange={(e) => setCheck1(e.target.checked)}
            name="checkedB"
            color="primary"
          />
        }
        label={
          <small>I agree with the Cautions when using SafeLuS service.</small>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={check2}
            onChange={(e) => setCheck2(e.target.checked)}
            name="checkedB"
            color="primary"
          />
        }
        label={
          <small>
            {library.I_HAVE_READ_AND_AGREE_TO_THE}{" "}
            <a
              href="https://docs.google.com/document/d/1sunOc2o0BppXXIto3kAx7LYYZWz5Enb-6htnzuxsOg0/edit"
              target="_blank"
              className="color-primary"
              rel="noreferrer"
            >
              {library.TERM_OF_SERVICES_AND_DISCLAIMER}
            </a>
          </small>
        }
      />
      <div className="error-message">{policyError}</div>
      <div
        className="d-flex-ce"
        style={{ position: "absolute", bottom: 30, right: 20 }}
      >
        <Button
          className="btn-cancel"
          style={{ width: 100, marginRight: 10, marginTop: 0 }}
          onClick={() =>
            dispatch({
              type: SHOW_CREATE_STAKE_FROM,
              payload: false,
            })
          }
        >
          Decline
        </Button>
        <Button
          className="btn-submit"
          style={{ width: 100, marginTop: 0 }}
          onClick={_handlePolicy}
        >
          Agree
        </Button>
      </div>
    </>
  );
}

function CreateStakeForm() {
  const { safeLus } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { showCreateStakeForm } = safeLus;
  const [stakeType, setStakeType] = useState("create");
  const [isDisclaimer, setIsDisclaimer] = useState(false);

  useEffect(() => {
    dispatch(_getStakingV1());
  }, [dispatch]);

  useEffect(() => {
    setIsDisclaimer(false);
    setStakeType("create");
  }, [showCreateStakeForm]);

  return (
    <Drawer
      anchor={"left"}
      open={showCreateStakeForm}
      className={"custom-modal-vk"}
    >
      <Container className="gray-bg white-color">
        <HighlightOffSharpIcon
          className="close-icon"
          onClick={() =>
            dispatch({
              type: SHOW_CREATE_STAKE_FROM,
              payload: false,
            })
          }
        />
        <div className="create-stake-form">
          <div
            className="reward app-header"
            style={{ background: `url(${background})`, opacity: 0.6 }}
          >
            <Avatar alt="SafeLuS" src={logo} sizes="large" />
            <div className="logo-text">
              <label>SAFE</label>
              <span>LuS</span>
            </div>
          </div>
          <div style={{ display: isDisclaimer ? "block" : "none" }}>
            <div className="form">
              {stakeType === "create" ? (
                <CreateStake
                  _back={() => setIsDisclaimer(false)}
                  stakeType={stakeType}
                  _setStakeType={(e) => setStakeType(e)}
                />
              ) : (
                <Transfer
                  _back={() => setIsDisclaimer(false)}
                  stakeType={stakeType}
                  _setStakeType={(e) => setStakeType(e)}
                />
              )}
            </div>
          </div>
          <div
            className="form policy"
            style={{ display: !isDisclaimer ? "block" : "none" }}
          >
            <Policy _next={() => setIsDisclaimer(true)} />
          </div>
          {/* <Upgrade /> */}
        </div>
      </Container>
    </Drawer>
  );
}

export default CreateStakeForm;
