import { Container } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { _getPoolList, _getUserStatistic } from "../actions/safeLusActions";
import LusStatistic from "../components/LusStatistic";
import StakeHistory from "../components/StakeHistory";
import TopHolder from "../components/TopHolder";
import TotalPoor from "../components/TotalPoor";
import UserMenu from "../components/UserMenu";

function Dashboard() {
  const [index, setIndex] = useState(0);
  const dispatch = useDispatch();
  const { setting } = useSelector((state) => state);
  const { library } = setting;

  useEffect(() => {
    dispatch(_getPoolList());
    dispatch(_getUserStatistic());
  }, [dispatch]);

  return (
    <>
      <UserMenu />
      <Container>
        <div className="bg-content">
          <LusStatistic />
          <TotalPoor />
          <div className="mt-30 mb-10 dashboard-option">
            <div
              className={index === 0 ? "active" : ""}
              onClick={() => setIndex(0)}
            >
              {library.STAKE_HISTORY}
            </div>
            <div
              className={index === 1 ? "active" : ""}
              onClick={() => setIndex(1)}
            >
              {library.TOP_HOLDER}
            </div>
          </div>
          {index ? <TopHolder /> : <StakeHistory />}
        </div>
      </Container>
    </>
  );
}

export default Dashboard;
