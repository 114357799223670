import { combineReducers } from "redux";
import { SafeLusReducer } from "./safeLusReducer";
import { SettingReducer } from "./settingReducer";


const rootReducer = combineReducers({
  safeLus: SafeLusReducer,
  setting: SettingReducer
});

export default rootReducer;
