import { GET_PROFILE } from "../constants";
import { UPDATE_SCREEN_MODE } from "../reducer/constant";
import { API_GET_PROFILE } from "../settings/apiEndpoint";
import { get } from "../utils/api";
import { isLoggedIn } from "../utils/auth";

export const _getProfile = () => (dispatch) => {
  if (isLoggedIn()) {
    get(API_GET_PROFILE, (data) => {
      dispatch({ type: GET_PROFILE, payload: data });
    });
  }
};

export const _updateScreenMode = (mode) => (dispatch) => {
  dispatch({ type: UPDATE_SCREEN_MODE, payload: mode });
};
