/* eslint-disable jsx-a11y/alt-text */
import { Divider, LinearProgress, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { _getPoolList } from "../actions/safeLusActions";
import { LUS_TOKEN } from "../constants";
import { formatLuS, formatUSD } from "../settings/format";
import CustomTooltip from "./CustomTooltip";

const LoadingTime = 60000;

function Pool({ type, title, content, image }) {
  const { safeLus, setting } = useSelector((state) => state);
  const { poolList, lusPrice } = safeLus;
  const { library } = setting;
  const [day, setDay] = useState(0);
  const [hour, setHour] = useState(0);
  const [minute, setMinute] = useState(0);
  const [second, setSecond] = useState(0);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  if (poolList) {
    var pool = poolList.filter((item) => item.type === type)[0];
  }

  useEffect(() => {
    if (pool) {
      const interval = setInterval(() => {
        var delta = Math.abs(pool.nextRelease - Date.now()) / 1000;
        if (pool.nextRelease - Date.now() > 0) {
          var days = Math.floor(delta / 86400);
          delta -= days * 86400;
          var hours = Math.floor(delta / 3600) % 24;
          delta -= hours * 3600;
          var minutes = Math.floor(delta / 60) % 60;
          delta -= minutes * 60;
          var seconds = Math.floor(delta % 60);
          setDay(days > 9 ? days : "0" + days);
          setHour(hours > 9 ? hours : "0" + hours);
          setMinute(minutes > 9 ? minutes : "0" + minutes);
          setSecond(seconds > 9 ? seconds : "0" + seconds);
          setLoading(false);
        } else {
          setLoading(true);
          clearInterval(interval);
          setTimeout(() => {
            setLoading(false);
            dispatch(_getPoolList());
          }, LoadingTime);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [dispatch, pool]);

  return pool ? (
    <div className="pool">
      <CustomTooltip
        style={{
          position: "absolute",
          top: "0.5em",
          right: "0.5em",
        }}
        content={content}
      />
      <div className="gray-bg pt-20 br-5 pb-20 text-center w-100pc mobile-br-0 mobile-pt-10 mobile-pb-10 content">
        <img
          src={image}
          style={{
            width: 60,
            height: "auto",
            marginBottom: "1em",
          }}
        />
        <Typography>{title}</Typography>
        <Typography variant="h5" className="text-bold">
          {formatLuS(pool.currentVolume.toFixed(8))} {LUS_TOKEN}
        </Typography>
        <Typography variant="h5">
          &#8776;{" "}
          {formatUSD(
            Math.round(pool.currentVolume.toFixed(8) * lusPrice * 100) / 100
          )}
          <small>$</small>
        </Typography>
        <Divider className="mt-20 mb-20 mobile-mt-10 mobile-mb-10" />
        {!loading ? (
          <div className="countdown">
            <div>
              <div className="value">{day}</div>
              <div className="label">{library.DAYS}</div>
            </div>
            <div>
              <div className="value">{hour}</div>
              <div className="label">{library.HOURS}</div>
            </div>
            <div>
              <div className="value">{minute}</div>
              <div className="label">{library.MINUTES}</div>
            </div>
            <div>
              <div className="value">{second}</div>
              <div className="label">{library.SECONDS}</div>
            </div>
          </div>
        ) : (
          <div>
            <div className="text-progress ">Payment inprogress</div>
            <div className="process">
              <RenderLoading />
            </div>
          </div>
        )}
      </div>
    </div>
  ) : null;
}

export default Pool;

function RenderLoading() {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress >= 90) {
          clearInterval(timer);
        }
        const diff = Math.random() * 5;
        return Math.min(oldProgress + diff, 100);
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);
  return <LinearProgress variant="determinate" value={progress} />;
}
