import { Container } from "@material-ui/core";
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

const brands = [
  "/images/burberry.png",
  "/images/bottega-vaneta.png",
  "/images/yves-saint-laurent.png",
  "/images/balenciaga.png",
  "/images/gucci.png",
  "/images/fendi.png",
  "/images/valentino-logo.jpeg",
  "/images/thom-browne-logo.jpeg",
  "/images/coach-logo.jpeg",
  "/images/dsquared2-logo.png",
  "/images/dng-logo.jpeg",
  "/images/chanel-logo.jpeg",
  "/images/montblanc-logo.png",
];

function Brands() {
  return (
    <div className="brands">
      <Container>
        <Carousel
          responsive={responsive}
          autoPlay={true}
          infinite={true}
          arrows={false}
        >
          {brands.map((item, index) => (
            <div className="item" key={index}>
              <img src={item} alt="brands" />
            </div>
          ))}
        </Carousel>
      </Container>
    </div>
  );
}

export default Brands;
