import { Warning } from "@material-ui/icons";
import React from "react";

function Upgrade() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: 50,
      }}
    >
      <Warning style={{ fontSize: 100, fill: "yellow" }} />
      <h3>Server is temporarily unavailable.</h3>
    </div>
  );
}

export default Upgrade;
