import { toast } from "react-toastify";
import { Icon } from "semantic-ui-react";

export const CustomToast = (type, content) => {
  if (type === "error") {
    toast(
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignContent: "center",
        }}
      >
        <div style={{ color: "#fe644e" }}>
          <Icon name="x" size="large" />
        </div>
        <div>{content}</div>
      </div>
    );
  } else {
    toast(
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignContent: "center",
        }}
      >
        <div style={{ color: "#03a89d" }}>
          <Icon name="checkmark" size="large" />
        </div>
        <div>
          <label className="title">{content}.</label>
        </div>
      </div>
    );
  }
};
