import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { formatLusFixDecimal } from "../../settings/format";
import { LUS_TOKEN } from "../../constants";
import { _getTransaction } from "../../actions/safeLusActions";
import { CircularProgress, TablePagination } from "@material-ui/core";

export default function MyPoolHistory({ type, showDisplayName }) {
  const { safeLus, setting } = useSelector((state) => state);
  const { transactions } = safeLus;
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { library } = setting;

  useEffect(() => {
    dispatch(_getTransaction(type, page, pageSize));
  }, [dispatch, page, pageSize, type]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value));
    setPage(1);
  };

  return (
    <div
      className="gray-bg pd-20 mobile-p-0 mobile-br-0 br-5"
      style={{ color: "#fff" }}
    >
      <TableContainer>
        <Table size="small" aria-label="a dense table">
          <TableHead className="white-color hide-mobile opacity-08">
            <TableRow>
              <TableCell>ID</TableCell>
              {showDisplayName && <TableCell>{library.ACCOUNT}</TableCell>}
              <TableCell>
                {library.AMOUNT} ({LUS_TOKEN})
              </TableCell>
              <TableCell>{library.TIME}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions ? (
              transactions.items.length > 0 ? (
                transactions.items.map((row) => (
                  <>
                    <TableRow key={row.name} className="hide-mobile">
                      <TableCell component="th" className="text-bold">
                        #{row.id}
                      </TableCell>
                      {showDisplayName && (
                        <TableCell className="text-bold">
                          {row.downlineDisplayName}
                        </TableCell>
                      )}
                      <TableCell className="text-bold">
                        {formatLusFixDecimal(row.amount)}
                      </TableCell>
                      <TableCell>
                        {moment(row.createdDate).format("MM-DD-YYYY HH:mm:ss")}
                      </TableCell>
                    </TableRow>
                    <TableRow
                      key={row.name}
                      className="show-mobile show-mobile-table-row"
                    >
                      <TableCell colSpan={4}>
                        <div className="d-flex-sbt">
                          <div>
                            <div className="text-bold">SL_{row.userId}</div>
                            <div>
                              <small>
                                {moment(row.createdDate).format(
                                  "MM-DD-YYYY HH:mm:ss"
                                )}
                              </small>
                            </div>
                          </div>
                          <div style={{ textAlign: "right" }}>
                            <div className="text-bold">
                              {formatLusFixDecimal(row.amount)} {LUS_TOKEN}
                            </div>
                            <div>
                              <small>{row.status}</small>
                            </div>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  </>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={showDisplayName ? 4 : 3}
                    className="text-center"
                  >
                    {library.NO_RECORDS_FOUND}
                  </TableCell>
                </TableRow>
              )
            ) : (
              <TableRow>
                <TableCell colSpan={showDisplayName ? 4 : 3}>
                  <div style={{ textAlign: "center" }}>
                    <CircularProgress style={{ margin: "auto" }} />
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {transactions && (
          <TablePagination
            component="div"
            count={transactions.itemCount}
            page={page - 1}
            onChangePage={handleChangePage}
            rowsPerPage={pageSize}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </TableContainer>
    </div>
  );
}
